require('./wizard.scss');

// @ vendors
const React = require('react');
const classnames = require('classnames');
const PropTypes = require('prop-types');
const { withRouter } = require('react-router-dom');

// @ utilities
const { scrollToTop } = require('commonsUtilities/scrollUtils');
import { recordLinkV2 } from "utilities/tagging"

// @ components
const PreLeaveModalComponent = require('components/preLeaveModalComponent/preLeaveModalComponent');
const WizardProgressIndicator = require('./wizardProgressIndicator/wizardProgressIndicator');
const WizardHeaderMenu = require('./wizardHeaderMenu/wizardHeaderMenu');
// @ helpers
const { formatText } = require('core/i18n').i18n;
// @ constants
const {
    recordLink,
    recordProcess,
    PROC_START,
    PROC_STEP, PROC_CANCEL,
} = require('utilities/tagging');
//@constants
const {
    WIZARD_HORIZONTAL_VIEW_MODE,
    WIZARD_VERTICAL_VIEW_MODE
} = require('constants/index');

class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.savedSteps = new Array(10); // for CC version
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleFinishClick = this.handleFinishClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleGoBackClick = this.handleGoBackClick.bind(this);
        this.buildStep = this.buildStep.bind(this);
    }

    componentDidMount() {
        const { processType, tealium, isTaggingSecondVersion, visibleStep, isCustomTagging, concractWizadLoad } = this.props;
        const step = visibleStep === 1 ? PROC_START : PROC_STEP + visibleStep;
        if (isCustomTagging) {
            concractWizadLoad && recordProcess(processType, step, tealium, isTaggingSecondVersion);
        } else {
            recordProcess(processType, step, tealium, isTaggingSecondVersion);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isTaggingSecondVersion,
            processType,
            tealium,
            visibleStep,
            viewMode
        } = this.props;

        if (visibleStep !== prevProps.visibleStep && viewMode === WIZARD_HORIZONTAL_VIEW_MODE) {
            recordProcess(processType, PROC_STEP + visibleStep, tealium, isTaggingSecondVersion);
            scrollToTop();
        }
    }

    componentWillUnmount() {
        scrollToTop();
    }

    buildPreLeaveModal() {
        const {
            onPreLeaveModalReady,
            showPreLeaveModal,
            preleaveModalTitle,
            preleaveModalMessage,
            preleaveModalLeftButtonConfig,
            preleaveModalRightButtonConfig,
            onConfirmLeave,
            onCloseButtonClick,
        } = this.props;

        let dataToRender;

        if (showPreLeaveModal) {
            const acceptText = formatText('wizard-redirectConfirmationAcceptButton');
            const cancelText = formatText('wizard-redirectConfirmationCancelButton');
            const confirmationTitle = preleaveModalTitle ? preleaveModalTitle : formatText('wizard-redirectConfirmationTitle');
            const confirmationMessage = preleaveModalMessage ? preleaveModalMessage : formatText('wizard-redirectConfirmationMessage');

            dataToRender = (
                <PreLeaveModalComponent
                    confirmationMessage={confirmationMessage}
                    confirmationTitle={confirmationTitle}
                    acceptText={acceptText}
                    cancelText={cancelText}
                    isReady={onPreLeaveModalReady}
                    leftButtonConfig={preleaveModalLeftButtonConfig}
                    rightButtonConfig={preleaveModalRightButtonConfig}
                    onConfirmLeave={() => {
                        if (!!onConfirmLeave) {
                            onConfirmLeave();
                        }
                    }}
                    onCloseButtonClick={() => {
                        if (!!onCloseButtonClick) {
                            onCloseButtonClick();
                        }
                    }}
                />
            );
        }

        return dataToRender;
    }

    buildStep() {
        return React.Children.map(this.props.children, (child, index) => {
            const { visibleStep, viewMode } = this.props;
            const active = visibleStep === index + 1;

            if (viewMode === WIZARD_HORIZONTAL_VIEW_MODE) {
                if (active) {
                    const { isFetching } = this.props;
                    let additionalProps = {
                        isFetching: !!isFetching,
                        actions: this.createStepActions()
                    };

                    if (child.props.addLoadingBehaviour) {
                        additionalProps.isLoading = child.props.isLoading || isFetching;
                    }

                    return React.cloneElement(child, additionalProps);
                }
            } else {
                if (active) { // the active step is always shown
                    const { isFetching } = this.props;
                    let additionalProps = {
                        isFetching: !!isFetching,
                        actions: this.createStepActions(),
                        stepNo: index + 1,
                        isVerticalView: (this.props.viewMode === WIZARD_VERTICAL_VIEW_MODE),
                        isShowOnly: false
                    };

                    if (child.props.addLoadingBehaviour) {
                        additionalProps.isLoading = child.props.isLoading || isFetching;
                    }

                    this.savedSteps[index] = React.cloneElement(
                        child,
                        additionalProps
                    );
                    this.savedSteps.splice(index + 1, this.savedSteps.length);

                    return (
                        <div className="wizard-vertical-step">
                            {this.savedSteps[index]}
                        </div>
                    );
                } else if (index < visibleStep || this.props.viewMode === WIZARD_VERTICAL_VIEW_MODE) { // previous steps are always shown; other steps shown only for viewMode===WIZARD_VERTICAL_VIEW_MODE
                    if (!this.savedSteps[index]) {
                        this.savedSteps[index] = React.cloneElement(child, {
                            isFetching: false,
                            isLoading: false,
                            isShowOnly: true,
                            actions: this.createStepActions(),
                            stepNo: index + 1,
                            isVerticalView: (this.props.viewMode === WIZARD_VERTICAL_VIEW_MODE)
                        });
                    }

                    return (
                        <div className="wizard-vertical-step wizard__disabled-step">
                            {this.savedSteps[index]}
                        </div>
                    );
                }
            }
        });
    }

    buildWizardHeaderMenu() {
        const { hasHeaderMenu, headerMenuData } = this.props;
        let headerMenu;

        if (hasHeaderMenu) {
            headerMenu = <WizardHeaderMenu headerMenuData={headerMenuData} />;
        }

        return headerMenu;
    }

    createStepActions() {
        return {
            handlePrevClick: this.handlePrevClick,
            handleNextClick: this.handleNextClick,
            handleCancelClick: this.handleCancelClick,
            handleFinishClick: this.handleFinishClick,
            handleGoBackClick: this.handleGoBackClick
        };
    }

    handleCancelClick() {
        const {
            actions: { cancel, setWillCancel },
            processType,
            tealium,
            isTaggingSecondVersion,
            tealiumEvents,
            tealiumForCancel,
        } = this.props;
        const updatedTealium = !!tealiumForCancel ? tealiumForCancel : tealium;
        setWillCancel(true);

        if (tealiumEvents?.cancel?.data) {
            recordLinkV2(tealiumEvents.cancel); 
        } else if (tealiumEvents && tealiumEvents.cancel) {
            recordLink(tealiumEvents.cancel.eventCategory || window.utag_last_name, tealiumEvents.cancel.eventName || '', tealiumEvents.cancel.eventTag || '');
        }

        if (cancel) {
            cancel();
            recordProcess(processType, PROC_CANCEL, updatedTealium, isTaggingSecondVersion);
        }
    }

    handleFinishClick() {
        const {
            actions,
            immSteps,
            tealiumEvents,
            visibleStep,
            history,
        } = this.props;

        if (tealiumEvents?.finish?.data) {
            recordLinkV2(tealiumEvents.finish); 
        } else if (tealiumEvents && tealiumEvents.finish) {
            recordLink(tealiumEvents.previous.eventCategory || window.utag_last_name, tealiumEvents.finish.eventName || '', tealiumEvents.finish.eventTag || '');
        }

        if (
            immSteps.get(visibleStep - 1).get('hasSignaturePattern') &&
            !immSteps.get(visibleStep - 1).has('valid')
        ) {
            //if has signaturePattern and no extra validation, then skip validation
            actions.finish(history);
        } else {
            // calling validateStep here is useless if is not returning the next state (valid in this case)
            // I left the previous implementation to not break existing flows.
            const valid = actions.validateStep(visibleStep, history);

            if (immSteps.get(visibleStep - 1).get('valid') || (typeof valid === 'boolean' && valid)) {
                actions.finish(history);
            }
        }
    }

    handleGoBackClick() {
        this.props.actions.setWillGoBack(true);
    }

    handleNextClick() {
        const { actions, tealiumEvents, visibleStep, history } = this.props;
        
        tealiumEvents && 
        tealiumEvents.next &&
        tealiumEvents.next.data ?
            recordLinkV2(tealiumEvents.next):
            tealiumEvents && 
            tealiumEvents.next && recordLink(tealiumEvents.next.eventCategory || window.utag_last_name, tealiumEvents.next.eventName || '', tealiumEvents.next.eventTag || '');
        actions.validateStep(visibleStep, history);
    }

    handlePrevClick() {
        const { actions, tealiumEvents, visibleStep } = this.props;

        if (tealiumEvents?.previous?.data) {
            recordLinkV2(tealiumEvents.previous); 
        } else if (tealiumEvents && tealiumEvents.previous) {
            recordLink(tealiumEvents.previous.eventCategory || window.utag_last_name, tealiumEvents.previous.eventName || '', tealiumEvents.previous.eventTag || '');
        }

        actions.setVisibleStep(visibleStep - 1);
        if (actions.resetStep) {
            //reset leaving step
            actions.resetStep(visibleStep);
        }
        if (actions.previousClick) {
            actions.previousClick();
        }
    }

    render() {
        const { wizardClass, visibleStep, stepsAmount, immSteps, fullWidth, viewMode } = this.props;
        const step = this.buildStep();
        const preLeaveModal = this.buildPreLeaveModal();
        const wizardClassName = classnames(
            'wizard',
            { 'wizard-vertical': viewMode && __CONTACT_CENTER__ },
            wizardClass
        );

        let stepContent;

        let wizardHeader;
        if (viewMode === WIZARD_HORIZONTAL_VIEW_MODE) {
            const wizardHeaderMenuBlock = this.buildWizardHeaderMenu();

            wizardHeader = (
                <section className="wizard__header">
                    {wizardHeaderMenuBlock}
                    <WizardProgressIndicator
                        visibleStep={visibleStep}
                        stepsAmount={stepsAmount}
                        immSteps={immSteps}
                    />
                </section>
            );
        }

        if (fullWidth) {
            stepContent = (
                <section className="col-xs-offset-2 col-xs-8">
                    {step}
                </section>
            );
        } else {
            stepContent = step;
        }

        const wizardStepsClassName = classnames('wizard__steps', {
            'row no-gutter clearfix wizard__steps--full-width': fullWidth
        });

        return (
            <article className={wizardClassName}>
                {wizardHeader}
                <section className={wizardStepsClassName}>
                    {stepContent}
                </section>
                {preLeaveModal}
            </article>
        );
    }
}

Wizard.propTypes = {
    actions: PropTypes.object.isRequired,
    children: PropTypes.any,
    immSteps: PropTypes.object.isRequired,
    visibleStep: PropTypes.number.isRequired,
    wizardClass: PropTypes.string,
    stepsAmount: PropTypes.number,
    isFetching: PropTypes.bool,
    fullWidth: PropTypes.bool,
    setWizardSuccess: PropTypes.func,
    setWizardError: PropTypes.func,
    setWizardLoading: PropTypes.func,
    resetWizard: PropTypes.func,
    hasHeaderMenu: PropTypes.bool,
    headerMenuData: PropTypes.object,
    onConfirmLeave: PropTypes.func,
    onCloseButtonClick: PropTypes.func,
    processType: PropTypes.string,
    viewMode: PropTypes.number,

    //preleave modal related propTypes.

    preleaveModalLeftButtonConfig: PropTypes.shape({
        text: PropTypes.string,
        callback: PropTypes.func,
        redirect: PropTypes.bool
    }),
    preleaveModalRightButtonConfig: PropTypes.shape({
        text: PropTypes.string,
        callback: PropTypes.func,
        redirect: PropTypes.bool
    }),
    onPreLeaveModalReady: PropTypes.func,
    preleaveModalMessage: PropTypes.string,
    preleaveModalTitle: PropTypes.string,
    showPreLeaveModal: PropTypes.bool,
    tealium: PropTypes.object,
    tealiumEvents: PropTypes.object, // Tagging events when clicking in cancel, previous, next and finish buttons
    tealiumForCancel: PropTypes.object,
    isTaggingSecondVersion: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isCustomTagging: PropTypes.bool,
    concractWizadLoad: PropTypes.bool,
};

Wizard.defaultProps = {
    fullWidth: false,
    hasHeaderMenu: false,
    viewMode: WIZARD_HORIZONTAL_VIEW_MODE,
    onPreLeaveModalReady: () => {
        return false;
    },
    showPreLeaveModal: false,
    tealium: {},
    tealiumForCancel: {},
    isTaggingSecondVersion: false,
    isCustomTagging: false,
    concractWizadLoad: false,
};

module.exports = withRouter(Wizard);
