const { MORTGAGE_PRODUCT_NUMBER } = require('constants/mortgageRequestType');

const mortgageRequestPreApprovalCommons = {
    HOLDER_OPTION_ONE: '0',
    HOLDER_OPTION_ONE_LABEL: '1',
    HOLDER_OPTION_TWO: '1',
    HOLDER_OPTION_TWO_LABEL: '2',
    ONE_DEPENDENT: '1',
    EMPTY_STRING: '',
    LEGAL_AGE: 18,
    MORTGAGE_COLLECTIVE_PRODUCT_ALIAS: 'mortgage_generic', // mortgages generic alias for collectives
    GENERIC_UI_COMPONENT_ID: '5', // collective generic UI component
    MAX_DEPENDENTS: 20,
    MORTGAGE_MINIMUM_YEARS_AFI_OPTIONS: 5,
};

const mortgagesRequestOtherLoansCommons = {
    MONTHLY_FEES_LOANS: 'monthlyFeesLoans',
    PENDING_AMOUNT_LOANS: 'pendingAmountLoans',
    PERSONAL_LOANS: 'personalLoans',
    OTHER_MORTGAGE: 'otherMortgage',
    GOING_CANCEL_MORTGAGE: 'goingCancelMortgage',
    MONTHLY_FEES_MORTGAGE: 'monthlyFeesMortgage',
    PENDING_AMOUNT_MORTGAGE: 'pendingAmountMortgage',
    LOANS: 'Loans',
    MORTGAGES: 'Mortgage'
};

const constantsCalculateCuota = {
    HABITUAL_HOUSING: '01',
    SECOND_HOUSING: '15',
    NEW_HOUSE: 'NEW_HOUSE',
    OLD_HOUSE: 'OLD_HOUSE',
    MIN_VALUE_OF_HOUSING: '',
    MIN_VALUE_COST_OF_HOUSING: 40000,
    STEP_COST_OF_HOUSING: 1,
    MIN_MONEY: '',
    MIN_NEED_MONEY: 30000,
    MAX_NEED_MONEY: 3000000,
    STEP_NEED_MONEY: 1,
    MIN_TIME_MONEY_RETURN_DEFAULT: '',
    MIN_TIME_MONEY_RETURN: 5,
    MAX_TIME_MONEY_RETURN_HH: 30,
    MAX_TIME_MONEY_RETURN_SH: 25,
    STEP_TIME_MONEY_RETURN: 1,
    PERCENTAGE_HABITUAL_HOUSING: 80,
    PERCENTAGE_SECOND_HOUSING: 70,
    INITIAL_MAX_HOUSING_COST: 500000,
    FINAL_MAX_HOUSING_COST: 4000000,
    MONEY_NEED_PERCENTAGE_VALUE: 30000,
};

const maritalStatusOptions = {
    options: {
        CASADO_BIENES_GANANCIALES: 'mortgageRequestPreApprovalStepThree-marriedCommunityProperty',
        CASADO_REGIMEN_PARTICIPACION: 'mortgageRequestPreApprovalStepThree-marriedRegimeParticipation',
        CASADO_EN_SEPARACION_DE_BIENES: 'mortgageRequestPreApprovalStepThree-marriedSeparationProperty',
        DIVORCIADO: 'mortgageRequestPreApprovalStepThree-divorced',
        PAREJA_DE_HECHO: 'mortgageRequestPreApprovalStepThree-defactoCouple',
        SEPARADO: 'mortgageRequestPreApprovalStepThree-separated',
        SOLTERO: 'mortgageRequestPreApprovalStepThree-single',
        VIUDO: 'mortgageRequestPreApprovalStepThree-widower'
    },
    abbreviations: {
        CASADO_BIENES_GANANCIALES: 'mortgageRequestPreApprovalStepThree-marriedCommunityPropertyAbbrev',
        CASADO_REGIMEN_PARTICIPACION: 'mortgageRequestPreApprovalStepThree-marriedRegimeParticipationAbbrev',
        CASADO_EN_SEPARACION_DE_BIENES: 'mortgageRequestPreApprovalStepThree-marriedSeparationPropertyAbbrev'
    }
};

const homeOwnershipOptions = {
    ALQUILER: 'mortgageRequestPreApprovalStepThree-rent',
    FAMILIAR: 'mortgageRequestPreApprovalStepThree-family',
    PROPIEDAD_HIPOTECADA: 'mortgageRequestPreApprovalStepThree-mortgaged',
    PROPIEDAD_SIN_CARGAS: 'mortgageRequestPreApprovalStepThree-freeHome',
    OTROS: 'mortgageRequestPreApprovalStepThree-othersOption'
};

const laborSituationOptions = {
    options: {
        CUENTA_AJENA_MAYOR_OFFICIAL: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountOfficialWithPosition',
        CUENTA_AJENA_MAYOR_5: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountPermanentGreaterThan5',
        CUENTA_AJENA_ENTRE_2_Y_5: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountFixedBetween2And5',
        CUENTA_AJENA_ENTRE_6M_Y_2: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountFixedBetween6MAnd2',
        CUENTA_AJENA_MENOR_6M: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountFixedLessThan6M',
        INTERINO_MENOR_6M: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_interimLessThan6M',
        TEMPORAL: 'mortgageRequestPreApprovalStepThree-temporal',
        PENSIONISTA: 'mortgageRequestPreApprovalStepThree-pensioner',
        DESEMPLEADO: 'mortgageRequestPreApprovalStepThree-unemployed',
        AUTONOMO: 'mortgageRequestPreApprovalStepThree-autonomous'
    },
    abbreviations: {
        CUENTA_AJENA_MAYOR_5: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountGreaterThan5Abbrev',
        CUENTA_AJENA_ENTRE_2_Y_5: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountBetween2And5Abbrev',
        CUENTA_AJENA_ENTRE_6M_Y_2: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountFixedBetween6MAnd2',
        CUENTA_AJENA_MENOR_6M: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_employeeAccountFixedLessThan6M',
        INTERINO_MAYOR_5: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_intermGreaterThan5Abbrev',
        INTERINO_ENTRE_2_Y_5: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_intermBetween2And5Abbrev',
        INTERINO_ENTRE_6M_Y_2: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_intermBetween6MAnd2Abbrev',
        INTERINO_MENOR_6M: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_intermLessThan6MAbbrev',
        TEMPORAL: 'mortgageRequestPreApprovalStepThree-mortgageRequestPreApprovalStepThree_temporalAbbrev',
        PENSIONISTA: 'mortgageRequestPreApprovalStepThree-pensionerAbbrev'
    }
};

const studiesLevelOptions = {
    options: {
        SIN_ESTUDIOS: 'mortgageRequestPreApprovalStepThree-noEstudies',
        PRIMARIOS: 'mortgageRequestPreApprovalStepThree-primary',
        BACHILLER: 'mortgageRequestPreApprovalStepThree-bachelor',
        UNIVERSITARIOS_MEDIOS: 'mortgageRequestPreApprovalStepThree-academic',
        TITULADO_SUPERIOR: 'mortgageRequestPreApprovalStepThree-superior'
    },
    abbreviations: {
        BACHILLER: 'mortgageRequestPreApprovalStepThree-bachelorAbbrev',
        UNIVERSITARIOS_MEDIOS: 'mortgageRequestPreApprovalStepThree-academicAbbrev'
    }
};

const idModalsMortgageRequest = {
    DATE_OF_BIRTH: 'dateOfBirth',
    HOME_OWNER_SHIP: 'homeOwnership',
    WORK: 'work',
    MARITAL: 'marital',
    OTHER_LOANS_AND_MORTGAGES: 'otherLoansAndMortgages',
    INCOMES_AND_EXPENSES: 'incomesAndExpenses'
}

const mortgageProductCatalogIdentifiers = {
    DEFAULT: 'DEFAULT',
    COLLECTIVE: 'COLECTIVO'
};

const mortgageProductCatalogWFIdentifiers = {
    DEFAULT: 'N',
    COLLECTIVE: 'C'
};

const afiMortgageProductTypes = {
    FIJO: 'Fija',
    MIXTO: 'Mixta',
    VARIABLE: 'Variable'
};

const PREAPPROVAL_RECORD_LINK_TAGGING_PROCESS = {
    MODAL_MORTGAGE_MORE_INFORMATION: {
        EVENT_CATEGORY: 'simulador de hipotecas open privada',
        ACTION_EVENT_OPEN: 'abrir mas informacion hipoteca %s',
        ACTION_EVENT_CLOSE: 'cerrar mas informacion hipoteca %s'
    }
}

const PREAPPROVAL_CALCULATE_QUOTA_HEADER = {
    ITEMS: [
        {
            icon: 'calculateQuota-calculateQuota_headerIcon2',
            title: 'calculateQuota-headerIconTitle2',
            descriptionValue: 'calculateQuota-calculateQuota_headerIconText2',
            modalLinkTextValue: 'calculateQuota-calculateQuota_modalInfoFixedMortgage',
            modalLinkTextClassName: 'mortgage-request-header-calculate-quota__benefits-modal__text-container--text',
            mortgageType: MORTGAGE_PRODUCT_NUMBER.FIJO,
            mortgageTypeCopyLink: 'calculateQuota-calculateQuota_showMoreFixedMortgage'
        },
        {
            icon: 'calculateQuota-calculateQuota_headerIcon3',
            title: 'calculateQuota-headerIconTitle3',
            descriptionValue: 'calculateQuota-headerIconText3',
            modalLinkTextValue: 'calculateQuota-calculateQuota_modalInfoMixedMortgage',
            modalLinkTextClassName: 'mortgage-request-header-calculate-quota__benefits-modal__text-container--text',
            mortgageType: MORTGAGE_PRODUCT_NUMBER.MIXTO,
            mortgageTypeCopyLink: 'calculateQuota-calculateQuota_showMoreMixedMortgage'

        },
        {
            icon: 'calculateQuota-calculateQuota_headerIcon1',
            title: 'calculateQuota-headerIconTitle1',
            descriptionValue: 'calculateQuota-calculateQuota_headerIconText1',
            modalLinkTextValue: 'calculateQuota-calculateQuota_modalInfoVariableMortgage',
            modalLinkTextClassName: 'mortgage-request-header-calculate-quota__benefits-modal__text-container--text',
            mortgageType: MORTGAGE_PRODUCT_NUMBER.VARIABLE,
            mortgageTypeCopyLink: 'calculateQuota-calculateQuota_showMoreVariableMortgage'
        },

        {
            icon: 'calculateQuota-calculateQuota_headerIcon4',
            title: 'calculateQuota-headerIconTitle4',
            descriptionValue: 'calculateQuota-headerIconText4'
        }
    ],
    COLLECTIVE_ITEMS: [
        {
            icon: 'calculateQuota-calculateQuota_collectiveHeaderIcon1',
            title: 'calculateQuota-calculateQuota_collectiveHeaderIconTitle1',
            descriptionValue: 'calculateQuota-calculateQuota_collectiveHeaderDescriptionText1',
        },
        {
            icon: 'calculateQuota-calculateQuota_collectiveHeaderIcon2',
            title: 'calculateQuota-calculateQuota_collectiveHeaderIconTitle2',
            descriptionValue: 'calculateQuota-calculateQuota_collectiveHeaderDescriptionText2',
        },
        {
            icon: 'calculateQuota-calculateQuota_collectiveHeaderIcon3',
            title: 'calculateQuota-calculateQuota_collectiveHeaderIconTitle3',
            descriptionValue: 'calculateQuota-calculateQuota_collectiveHeaderDescriptionText3',

        },
        {
            icon: 'calculateQuota-calculateQuota_collectiveHeaderIcon4',
            title: 'calculateQuota-calculateQuota_collectiveHeaderIconTitle4',
            descriptionValue: 'calculateQuota-calculateQuota_collectiveHeaderDescriptionText4'
        }

    ]
}

module.exports = {
    constantsCalculateCuota,
    homeOwnershipOptions,
    laborSituationOptions,
    maritalStatusOptions,
    mortgagesRequestOtherLoansCommons,
    mortgageRequestPreApprovalCommons,
    studiesLevelOptions,
    idModalsMortgageRequest,
    mortgageProductCatalogIdentifiers,
    mortgageProductCatalogWFIdentifiers,
    afiMortgageProductTypes,
    PREAPPROVAL_RECORD_LINK_TAGGING_PROCESS,
    PREAPPROVAL_CALCULATE_QUOTA_HEADER
};
