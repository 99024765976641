//@constants
const {
    COUNTRY_CODE_ES,
    COUNTRY_CODE_PT,
    COUNTRY_CODE_DE,
    COUNTRY_CODE_FR,
    COUNTRY_CODE_NL,
} = require('commonsConstants/countryCodes');

module.exports = {
    ACCEPT_MONEY_REQUEST_STATUS: 2,
    ACCESS: 'ACCESS',
    ACCOUNT_CANCEL_CHECKS_DETERIORATED: 'T', //deteriorado
    ACCOUNT_CANCEL_CHECKS_LOST: 'E', //extraviado
    ACCOUNT_CANCEL_CHECKS_STOLEN: 'S', //sustraido
    ACCOUNT_CHEQUEBOOK_DETERIORATED: 'DETERIORAD',
    ACCOUNT_CHEQUEBOOK_MISSING: 'EXTRAVIADO',
    ACCOUNT_CHEQUEBOOK_NEW_DOCUMENT_NUMBERS: '20',
    ACCOUNT_CHEQUEBOOK_NEW_DOCUMENT_NUMBERS_NAME: 'VEINTE',
    ACCOUNT_CHEQUEBOOK_NEW_LANGUAGE: 'E',
    ACCOUNT_CHEQUEBOOK_STOLEN: 'SUSTRAIDO',
    ACCOUNT_CHEQUEBOOK_VALID: 'EN VIGOR',
    ACCOUNT_CHEQUE_CANCELED: 'ANULADO',
    ACCOUNT_CHEQUE_COMPOSED: 'COMPUESTO', // toDo: check against api
    ACCOUNT_CHEQUE_DETERIORATED: 'DETERIORAD',
    ACCOUNT_CHEQUE_IRREGULAR: 'ANOMALO', // toDo: check against api
    ACCOUNT_CHEQUE_MISSING: 'EXTRAVIADO',
    ACCOUNT_CHEQUE_PAYED: 'PAGADO',
    ACCOUNT_CHEQUE_PENDING: 'PENDIENTE',
    ACCOUNT_CHEQUE_RETURNED: 'DEVUELTO', // toDo: check against api
    ACCOUNT_CHEQUE_STOLEN: 'SUSTRAIDO',
    ACCOUNT_DEBITS_HAS_BEEN_ANNULATED: 'YA EXISTEN AVISOS.',
    ACCOUNT_DEBITS_ISSUING_COMPANY_LABEL_ALL: 'todas',
    ACCOUNT_DEBITS_MESSAGE_ANNULATION: 'ANULACION REALIZADA POR EL DEUDOR DESDE CANAL BANCA DISTANCIA',
    ACCOUNT_DEBITS_RECEIPT_API_STATUS_FILTER: 'ANU,PAN,APL,PTD,DEV',
    ACCOUNT_DEBITS_RECEIPT_DAY_TO_ALLOW_RETURN: 58,
    ACCOUNT_DEBITS_RECEIPT_STATUS_CANCELATION_PENDING: 'cancelationpending',
    ACCOUNT_DEBITS_RECEIPT_STATUS_CANCELED: 'canceled',
    ACCOUNT_DEBITS_RECEIPT_STATUS_PAID: 'paid',
    ACCOUNT_DEBITS_RECEIPT_STATUS_PENDING: 'pending',
    ACCOUNT_DEBITS_RECEIPT_STATUS_REJECTED: 'rejected',
    ACCOUNT_DEBITS_RECEIPT_STATUS_RETURNED: 'returned',
    ACCOUNT_DEBITS_TABLE_LIMIT: 15,
    ACCOUNT_DEFAULT_TRANSACTIONS_PAGE_NUMBER: 1,
    ACCOUNT_DEFERRABLE_PAYMENT: 'S',
    ACCOUNT_DEFERRED_PAYMENT: 'F',
    ACCOUNT_DETAILS_CERTIFICATE_SALDO: 'saldo',
    ACCOUNT_DETAILS_CERTIFICATE_TITULARIDAD: 'titularidad',
    ACCOUNT_DETAILS_VIEW_NAME: 'Cuenta - Detalles',
    ACCOUNT_EXPANDED_TRANSACTIONS_LIMIT: 5,
    ACCOUNT_INTERVENTION_ADMINISTRATOR: 'ADMINISTRADOR',
    ACCOUNT_INTERVENTION_ADMINISTRATOR_CODE: '04',
    ACCOUNT_INTERVENTION_AUTHORIZED: 'AUTORIZADO',
    ACCOUNT_INTERVENTION_AUTHORIZED_CODE: '08',
    ACCOUNT_INTERVENTION_CO_OWNER: 'COTITULAR',
    ACCOUNT_INTERVENTION_INDISTINCT_CODE: '02',
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE: 'REPRESENTANTE LEGAL',
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE: '09',
    ACCOUNT_INTERVENTION_OWNER: 'TITULAR',
    ACCOUNT_INTERVENTION_OWNER_CODE: '01',
    ACCOUNT_INTERVENTION_REPRESENTATIVE: 'APODERADO',
    ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE: '07',
    ACCOUNT_MOVEMENTS_TRANSFER_REUSE_EVENT_ACTION: 'reuse',
    ACCOUNT_MOVEMENTS_TRANSFER_REUSE_EVENT_CATEGORY: 'transfer',
    ACCOUNT_MOVEMENTS_TRANSFER_REUSE_EVENT_LABEL: 'movements/detail',
    ACCOUNT_MOVEMENT_INTERNATIONAL_TRANSFER_CODE: '696',
    ACCOUNT_MOVEMENT_SEPA_OR_INTERNAL_TRANSFER_CODE: '632',
    ACCOUNT_NEW_PRODUCT: '300',
    ACCOUNT_NOT_DEFERRABLE_PAYMENT: 'N',
    ACCOUNT_OWNERSHIP_PERSONAL: 'UNIPERSONAL',

    ACCOUNT_SHORT_LENGTH: 4,
    ACCOUNT_TYPE_CURRENT_WITH_FOREIGN_BALANCE: '803',
    ACCOUNT_TYPE_E_LOAN: 'e-account-loan',
    ACCOUNT_TYPE_JUNIOR: 'junior-account',
    ACCOUNT_TYPE_ONE_TWO_THREE: '807',
    ACCOUNT_TYPE_OPERATIVE: '800',
    ACCOUNT_TYPE_PAYROLL: '805',
    ACCOUNT_TYPE_SUBTYPE_PAYROLL: '300805',
    ACCOUNT_USE_PROFESSIONAL: 2,
    ACCOUNT_USE_PROFESSIONAL_INVESTMENT: 3,
    ACC_NUMBER_LENGTH: 50,
    ACC_NUMBER_REGEX: /^[A-Z0-9]{5,}?$/i,
    ACTIVE_TAB_CONTRACTS: 2,
    ADDITION: '+',
    ADDRESS_OTHERS_REGEX: /^[A-Za-z0-9ÑñÁÉÍÓÚáéíóú]{1,2}(\/{1}[A-Za-z0-9ÑñÁÉÍÓÚáéíóú]{1,2}){0,2}$/,
    ADDRESS_TYPE: 'addressType',
    ADD_DOCUMENTATION_BUTTON: 'addDocumentationButton',
    ADVANCED_INTERSTITIAL_CONTINUE_WITHOUT_PROMOTION_EVENT_ACTION: 'prefiero continuar sin la promocion',
    ADVANCED_INTERSTITIAL_ERROR_EVENT: 'error',
    ADVANCED_INTERSTITIAL_EVENT_ACTION: 'visualizacion',
    ADVANCED_INTERSTITIAL_SIGN_UP_EVENT_ACTION: 'darme de alta',
    ADVANCED_INTERSTITIAL_START_EVENT: 'inicio',
    ADVANCED_INTERSTITIAL_SUCCESS_EVENT: 'exito',
    ADVANCED_INTERSTITIAL_SUCCESS_PAGE_EVENT: 'perfil/estan-actualizados',
    AGGREGATOR_MOVEMENTS_FILTER: 'AGGREGATOR_MOVEMENTS_FILTER',
    AGGREGATOR_MOVEMENTS_RADIO_FILTER_ALL: 'AGGREGATOR_MOVEMENTS_RADIO_FILTER_ALL',
    AGGREGATOR_MOVEMENTS_RADIO_FILTER_INCOME: 'income',
    AGGREGATOR_MOVEMENTS_RADIO_FILTER_SPEND: 'expense',
    ALL_NOT_ALLOWED_COUNTRIES: `[${COUNTRY_CODE_DE},${COUNTRY_CODE_ES},${COUNTRY_CODE_FR},${COUNTRY_CODE_NL},${COUNTRY_CODE_PT}]`,
    AMAZON_COUPON_CODE_FILTER_STATE_PENDING: 'pending',
    AMAZON_GIFT_CARD_STATUS: 'RefundedToPurchaser',
    AMAZON_GIFT_CARD: "Cheque Regalo Amazon.es",
    AMOUNT_LIMIT_FROM: 0,
    AMOUNT_LIMIT_TO: 99999999,
    AMOUNT_TARGET_TYPE: 'amountTargetType',
    ANNUAL_INFO: 'annualInformation',
    API_CENTER: '0100', // center = fixed (confirmed by Juan Abate)
    API_CHANNEL: 'INT',
    API_ENTERPRISE_NUMBER: '0073', // enterprise number = fixed (confirmed by Juan Abate)
    API_WEB_CHANNEL: 'WEB',
    ASSET_ACTIVE_TAB: 1,
    AUTHORIZATIONS_FILTERS: 'authorizations',
    AUTHORIZATION_ACTIVE: 'active',
    AUTHORIZATION_ALL: 'allStatus',
    AUTHORIZATION_CANCELED: 'canceled',
    BACK_DNI: 'backDni',
    FRONT_DNI: 'frontDni',
    PASSPORT: 'passport',
    BACKSPACE_KEYCODE: 8,
    BANK_PHONE_NUMBER_CLIENTS_ONLY_1: '+34\u00a0900\u00a0247\u00a0365',  // +34 900 247 365.        (900/901 numbers should be grouped by 3 digits)
    BANK_PHONE_NUMBER_PREMIUM: '+34\u00a0900\u00a0247\u00a0366',  // +34 900 247 366.               (900/901 numbers should be grouped by 3 digits)
    BANNER_TYPE_ID_COOKIES: 'BANNER_TYPE_ID_COOKIES',
    BANNER_TYPE_ID_LOCAL: 'BANNER_TYPE_ID_LOCAL',
    BANNER_TYPE_ID_SESSION: 'BANNER_TYPE_ID_SESSION',
    BANNER_TYPE_LINK: 'BANNER_TYPE_LINK',
    BANNER_UPDATE_ID_GENERAL_VIEW: 'banner-gv',
    BASIC_PAYMENT_ACCOUNT_SUBTYPE: '810',
    BENEFICIARIES_LANDING_LIMIT: 5,
    BENEFICIARY_BANK_OPTION_BIC: 'Bic',
    BENEFICIARY_BANK_OPTION_NAME: 'Name',
    BENEFICIARY_IS_NOT_RESIDENT: 'N',
    BENEFICIARY_IS_RESIDENT: 'S',
    BENEFICIARY_TYPE_EXISTING: 'Existing',
    BENEFICIARY_TYPE_EXISTING_EDIT: 'EXISTING_EDIT',
    BENEFICIARY_TYPE_NEW: 'New',
    BENEFICIARY_TYPE_NGO: 'NGO',
    BIC_MAX_LENGTH: 11,
    BIC_PLACEHOLDER: 'XXXXXXXX / XXXXXXXXXXX',
    BIC_REGEX: /^[A-Z0-9]{8}([A-Z]{3})?$/i, // BIC has two valid lengths (8 or 11). If it's 11, the last three characters must be letters.
    BIC_REGEX_ALT: /^[A-Z0-9]{8,11}?$/i,
    BLOB_REGEX: /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/g,
    BLOCKED_DEVICES: 'BLOCKED',
    BOLSA_DE_BARCELONA: 'BOLSA DE BARCELONA',
    BOLSA_MADRID: 'BOLSA DE MADRID',
    BOLSA_VALORES_BILBAO: 'BOLSA DE VALORES DE BILBAO',
    BROKER_ACTIVE_CLASS_ACTIVOS_REALES: 'Activos reales',
    BROKER_ACTIVE_CLASS_GESTION_ALTERNATIVA: 'Gestión alternativa',
    BROKER_ACTIVE_CLASS_MERCADO_MONETATIO: 'Mercado monetario',
    BROKER_ACTIVE_CLASS_MIXTOS: 'Mixtos',
    BROKER_ACTIVE_CLASS_PRIVATE_EQUITY: 'privateEquity',
    BROKER_ACTIVE_CLASS_OTROS: 'Otros',
    BROKER_ACTIVE_CLASS_RENTA_FIJA: 'Renta fija',
    BROKER_ACTIVE_CLASS_RENTA_VARIABLE: 'Renta variable',
    BROKER_ACTIVE_CLASS_MAXCHARACTERS: 16,
    BROKER_ADVANCED_SEARCH_CHANGE_TAB: 'BROKER_ADVANCED_SEARCH_CHANGE_TAB',
    BROKER_ADVANCED_SEARCH_CURRENCIES_SPLIT_STRING: '$$$$$',
    BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE: 'all',
    BROKER_ADVANCED_SEARCH_DEFAULT_CURRENCY_VALUE: 'CU$$$$$EUR',
    BROKER_ADVANCED_SEARCH_ETFS_REQUEST_IDENTIFIER: '4',
    BROKER_ADVANCED_SEARCH_ETFS_SPCSYMBOL_MARKETS: 'IndicesRef_0073',
    BROKER_ADVANCED_SEARCH_FUND_EXTERNAL_SEARCH_UNIVERSE_ID: 'FOESP$$ALL',
    BROKER_ADVANCED_SEARCH_FUND_UNIVERSE_ID: 'FOEUR$$ALL_2118',
    BROKER_ADVANCED_SEARCH_ID_CLASSIFICATION_TYPE_ADMINISTRATOR: 6,
    BROKER_ADVANCED_SEARCH_ID_CLASSIFICATION_TYPE_CATEGORY: 8,
    BROKER_ADVANCED_SEARCH_ID_CLASSIFICATION_TYPE_MARKET: 7,
    BROKER_ADVANCED_SEARCH_SLIDER_MAX: 20,
    BROKER_ADVANCED_SEARCH_SLIDER_MIN: 0,
    BROKER_ADVANCED_SEARCH_SLIDER_RANGE: 2,
    BROKER_ADVANCED_SEARCH_STOCKS_REQUEST_IDENTIFIER: '1',
    BROKER_ADVANCED_SEARCH_STOCKS_SPCSYMBOL_MARKETS: 'IndicesRef_0073',
    BROKER_ADVANCED_SEARCH_URL_FILTER_DATA_POINTS: 'CategoryId|LargestRegion|AdministratorCompanyId|ReturnM1|ReturnM3|ReturnM6|ReturnM12|ReturnM36|ReturnM60|ReturnM120|ManagementFee|CustomCategoryId|CurrencyId',
    BROKER_ADVANCED_SEARCH_URL_FUNDS_SECURITY_DATA_POINTS: 'SecId|ISIN|Name|CustomInstitutionSecurityID|CustomCategoryIdName|Rating|CustomRisk|ReturnM0|ReturnM12|ReturnM36|ReturnM60|CustomMinimumPurchaseAmount|CustomMinimumPurchaseAmountUnit|CustomExternalURL1|CustomExternalURL2|Currency|CustomTransferTaxExempt|srri|KiidDocument|CustomCategoryId3',
    BROKER_ADVANCED_SEARCH_URL_PLANS_SECURITY_DATA_POINTS: 'SecId|Name|DGSCode|CustomInstitutionSecurityID|CurrencyId|CustomCategoryId|CustomCategoryIdName|AdministratorCompanyId|AdministratorCompanyName|Rating|CustomRisk|ReturnM0|ReturnM12|ReturnM36|ReturnM60|ReturnM120|ManagementFee|CustodianFee|CustomMinimumPurchaseAmount|CustomMinimumPurchaseAmountUnit|CustomMinimumAdditionalPurchaseAmount|CustomMinimumAdditionalPurchaseAmountUnit|CustomPurchaseCurrency|srri|KiidDocument',
    BROKER_ADVANCED_SEARCH_UTAG_CART_ADD: 'cart_add',
    BROKER_ADVANCED_SEARCH_UTAG_CLEAN_FILTERS: 'limpiar campos',
    BROKER_ADVANCED_SEARCH_UTAG_EDIT_SEARCH: 'editar busqueda',
    BROKER_ADVANCED_SEARCH_UTAG_INVESTMENT_BREADCRUMB: 'volver a inversiones',
    BROKER_ADVANCED_SEARCH_UTAG_LINK_NAME: 'product_click',
    BROKER_ADVANCED_SEARCH_UTAG_NAME: 'Buscador/avanzado',
    BROKER_ADVANCED_SEARCH_UTAG_SEARCH: 'buscar',
    BROKER_ADVANCED_SEARCH_VIEW_ADVANCED_SEARCH_TAB: 1,
    BROKER_ADVANCED_SEARCH_VIEW_SIMPLE_SEARCH_TAB: 0,
    BROKER_AEX_STOCK_MARKET_NAME: 'AEX',
    BROKER_ALERT_BDP: 'BDP',
    BROKER_ALERT_BDV: 'BDV',
    BROKER_ALERT_BUY_BUTTON_ETF: 'ETF',
    BROKER_ALERT_BUY_BUTTON_STOCK: 'STK',
    BROKER_ALERT_BUY_BUTTON_WARRANT: 'WRTN',
    BROKER_ALERT_CMN: 'CMN',
    BROKER_ALERT_CMY: 'CMY',
    BROKER_ALERT_CONFIGURATION_POINTS_NAME: 'PUNTOS',
    BROKER_ALERT_CONFIGURATION_TYPE_IBEX_35_OPTION: 'ibex35',
    BROKER_ALERT_CONFIGURATION_TYPE_STOCKS_OPTION: 'stocks',
    BROKER_ALERT_DATE_FORMAT: 'DD MMM YYYY | HH:mm',
    BROKER_ALERT_FRACTION_LENGTH_EURO_VALUE: 3,
    BROKER_ALERT_FRACTION_LENGTH_PERCENTAGE_IBEX35: 3,
    BROKER_ALERT_FRACTION_LENGTH_PERCENTAGE_VALUE: 3,
    BROKER_ALERT_FRACTION_LENGTH_POINTS_VALUE: 3,
    BROKER_ALERT_INDEX_ID_IBEX_35: 'IBEX35',
    BROKER_ALERT_MAXIMUM_EURO_VALUE: 9999.999,
    BROKER_ALERT_MAXIMUM_PERCENTAGE_IBEX35: 200.999,
    BROKER_ALERT_MAXIMUM_PERCENTAGE_IBEX35_VALUE: 200.999,
    BROKER_ALERT_MAXIMUM_PERCENTAGE_VALUE: 200.999,
    BROKER_ALERT_MAXIMUM_POINTS_VALUE: 99999.999,
    BROKER_ALERT_MAXIMUN_INTEGER_EURO_LENGTH: 4,
    BROKER_ALERT_MAXIMUN_INTEGER_PERCENTAGE_IBEX35_LENGTH: 3,
    BROKER_ALERT_MAXIMUN_INTEGER_PERCENTAGE_LENGTH: 3,
    BROKER_ALERT_MAXIMUN_INTEGER_POINTS_LENGTH: 5,
    BROKER_ALERT_MINIMUM_EURO_QUOTATION_VALUE: 0,
    BROKER_ALERT_MINIMUM_EURO_VALUE: 0.001,
    BROKER_ALERT_MINIMUM_PERCENTAGE_IBEX35: 0.001,
    BROKER_ALERT_MINIMUM_PERCENTAGE_VALUE: 0.001,
    BROKER_ALERT_MINIMUM_POINTS_VALUE: 0.001,
    BROKER_ALERT_PAGE_NUM: 1,
    BROKER_ALERT_PAGE_SIZE: 1,
    BROKER_ALERT_PERCENTAGE_CONDITIONS_IDS: ['VMN', 'VMY', 'BDV', 'SDV'],
    BROKER_ALERT_RATE_IBEX35_CERO_EURO_PERCENTAGE_VALUE: 0,
    BROKER_ALERT_RATE_VALUE_CERO_EURO_PERCENTAGE_VALUE: 0,
    BROKER_ALERT_SDP: 'SDP',
    BROKER_ALERT_SDV: 'SDV',
    BROKER_ALERT_VMN: 'VMN',
    BROKER_ALERT_VMY: 'VMY',
    BROKER_ALL_INDEXES_EXCHANGE_ID: 0,
    BROKER_ALL_INDEXES_PAGE_SIZE: 8,
    BROKER_ALL_INDEXES_SPC_SYMBOL: 'OB_Lista_Indices',
    BROKER_ANY_MARKET: 'CUALQUIERA',
    BROKER_BUY_FOR_AMOUNT_OF_TITLES: 'BROKER_BUY_FOR_AMOUNT_OF_TITLES',
    BROKER_BUY_FOR_APPROX_IMPORT: 'BROKER_BUY_FOR_APPROX_IMPORT',
    BROKER_BUY_SCREEN: 'BUY',
    BROKER_CALLMEBACK_CALL_TIME_SOON_AS_POSSIBLE: 'BROKER_CALLMEBACK_CALL_TIME_SOON_AS_POSSIBLE',
    BROKER_CALLMEBACK_INITIAL_TEL_CODE: 34,
    BROKER_CALLMEBACK_MAX_COMMENT_CHARS: 100,
    BROKER_CALLMEBACK_PAGE_PATH: '/myprofile/broker',
    BROKER_CALLMEBACK_PAGE_TITLE: 'Broker',
    BROKER_CALLMEBACK_TYPE: 'broker',
    BROKER_CALLMEBACK_VALID_TEL_CODE_REG_EX: /^(\+?\d{1,5})$/,
    BROKER_CALLMEBACK_VALID_TEL_REG_EX: /^(\d{9,13})$/,
    BROKER_CAPITAL_INCREASE_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION: 'LEAFLET_SUSBCRIPTION',
    BROKER_CAPITAL_INCREASE_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION: 'OFFICIAL_COMMUNICATION',
    BROKER_CASH_CHARGE_SIGNATURE_PATTERN_PARAM: '00730100',
    BROKER_CASH_CHARGE_SIGNATURE_PATTERN_TYPE: '43',
    BROKER_CATEGORY_WARRANT: 'WNT',
    BROKER_CHECK_ALLOWED_CCV_CONTRACT_PARAM: 'TR',
    BROKER_CHECK_NOT_ALLOWED_CCV_CONTRACT_ERROR: 'CCV not allowed',
    BROKER_COMMISIONS_URL: 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431488321111&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank/DocumentoGS/DocumentoGSPreview',
    BROKER_CONDITIONAL_ORDER_TERM: 'conditional',
    BROKER_CONDITION_OVER_UNDERLYING_PRICE: 'BROKER_CONDITION_OVER_UNDERLYING_PRICE',
    BROKER_CONDITION_OVER_WARRANT_PRICE: 'BROKER_CONDITION_OVER_WARRANT_PRICE',
    BROKER_CONTRACT_ACTIVITIES_DATE_LIMIT_YEARS_FROM_NOW: 7,
    BROKER_CONTRACT_FUND_TYPE: 'FONDOS',
    BROKER_CONTRACT_OWNER: 'broker-stocksContractOwner',
    BROKER_CONTRACT_PLAN_TYPE: 'PLANES',
    BROKER_CONTRACT_VALUE_TYPE: 'VALORES',
    BROKER_CONTRIBUTION_MANAGMENT_URL: '/myprofile/broker/contributionManagment',
    BROKER_CONTRIBUTION_PLAN_FRACTION_LENGTH_AMOUNT_VALUE: 2,
    BROKER_CONTRIBUTION_PLAN_FRACTION_LENGTH_PERCENTAGE_VALUE: 2,
    BROKER_CONTRIBUTION_PLAN_MAXIMUN_INTEGER_AMOUNT_LENGTH: 6,
    BROKER_CONTRIBUTION_PLAN_MAXIMUN_INTEGER_PERCENTAGE_LENGTH: 3,
    BROKER_CONTRIBUTION_PLAN_MAXIMUN_PERCENTAGE_VALUE: 100.00,
    BROKER_CONTRIBUTION_PLAN_MINIMUM_PERCENTAGE_VALUE: 0.001,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_LABEL: 'Según IPC',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_VALUE: 'SEGUN_IPC',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_LABEL: 'Sin revalorización',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_VALUE: 'SIN_REVALORACION',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_LABEL: 'Según porcentaje fijo',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_VALUE: 'PORCENTAJE_FIJO',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_CODE: 3,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_LABEL: 'Anual',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_VALUE: 'ANUAL',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_CODE: 2,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_LABEL: 'Semestral',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_VALUE: 'SEMESTRAL',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BLANK_CODE: -1,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_CODE: 0,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_LABEL: 'Mensual',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_VALUE: 'MENSUAL',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_CODE: 1,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_LABEL: 'Trimestral',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_VALUE: 'TRIMESTRAL',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_WEEK_LABEL: 'Semanal',
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_WEEK_VALUE: 'SEMANAL',
    BROKER_CONVENIENCE_MIFID_CM_BUY: 'BROKER_CONVENIENCE_MIFID_CM_BUY',
    BROKER_CONVENIENCE_MIFID_EMPTY_EVENT_CODE: '',
    BROKER_CONVENIENCE_MIFID_EMPTY_FEE_NUMBERS: '',
    BROKER_CONVENIENCE_MIFID_ETF: 'BROKER_CONVINIENCE_MIFID_ETF',
    BROKER_CONVENIENCE_MIFID_ETF_BUY: 'BROKER_CONVENIENCE_MIFID_ETF_BUY',
    BROKER_CONVENIENCE_MIFID_FUND: 'BROKER_CONVENIENCE_MIFID_FUND',
    BROKER_CONVENIENCE_MIFID_FUND_BUY: 'BROKER_CONVENIENCE_MIFID_FUND_BUY',
    BROKER_CONVENIENCE_MIFID_FUND_SUSCRIBE: 'BROKER_CONVENIENCE_MIFID_FUND_SUSCRIBE',
    BROKER_CONVENIENCE_MIFID_MOTIVE_EXPIRED: '2',
    BROKER_CONVENIENCE_MIFID_MOTIVE_SUSPENDED: '1',
    BROKER_CONVENIENCE_MIFID_MOTIVE_TAKE_TEST: '0',
    BROKER_CONVENIENCE_MIFID_STOCK: 'BROKER_CONVINIENCE_MIFID_STOCK',
    BROKER_CONVENIENCE_MIFID_STOCK_BUY: 'BROKER_CONVENIENCE_MIFID_STOCK_BUY',
    BROKER_CONVENIENCE_MIFID_TEST_ETF_ID: '18',
    BROKER_CONVENIENCE_MIFID_TEST_ETF_INT_ID: '20',
    BROKER_CONVENIENCE_MIFID_TEST_EXTERNAL_TRANSFER_FUND: 'BROKER_CONVENIENCE_MIFID_TEST_EXTERNAL_TRANSFER_FUND',
    BROKER_CONVENIENCE_MIFID_TEST_FIRST_STEP_EXPERIENCE_MORE_THAN_A_YEAR_ANSWER: '137',
    BROKER_CONVENIENCE_MIFID_TEST_FIRST_STEP_EXPERIENCE_WITH_KNOWLEDGE_ANSWER: '138',
    BROKER_CONVENIENCE_MIFID_TEST_FIRST_STEP_STUDY_UNIVERSITARY_ANSWER: '132',
    BROKER_CONVENIENCE_MIFID_TEST_FUTURES_CREATE: 'BROKER_CONVENIENCE_MIFID_TEST_FUTURES_CREATE',
    BROKER_CONVENIENCE_MIFID_TEST_FUTURES_PLATFORM_ACCESS: 'BROKER_CONVENIENCE_MIFID_TEST_FUTURES_PLATFORM_ACCESS',
    BROKER_CONVENIENCE_MIFID_TEST_INTERNAL_TRANSFER_FUND: 'BROKER_CONVENIENCE_MIFID_TEST_INTERNAL_TRANSFER_FUND',
    BROKER_CONVENIENCE_MIFID_TEST_MANUAL_ID: '',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_CASH_CHARGE: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_CASH_CHARGE',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_CHANGE: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_CHANGE',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_OPA: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_OPA',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_OPV: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_OPV',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_REQUEST_CHANGE: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_REQUEST_CHANGE',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_RIGHTS_BUY: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_RIGHTS_BUY',
    BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_SUBSCRIPTION: 'BROKER_CONVENIENCE_MIFID_TEST_OPERATION_TYPE_CORPORATE_OPERATIONS_SUBSCRIPTION',
    BROKER_CONVENIENCE_MIFID_TEST_SECOND_STEP_FINANCIAL_CULTURE_GENERAL_CONCEPTS_ANSWER: '142',
    BROKER_CONVENIENCE_MIFID_TEST_SECOND_STEP_FINANCIAL_CULTURE_NONE_ANSWER: '141',
    BROKER_CONVENIENCE_MIFID_TEST_SIGNATURE_OPERATION_PARAMS: {},
    BROKER_CONVENIENCE_MIFID_TEST_SIGNATURE_OPERATION_TYPE: 101,
    BROKER_CONVENIENCE_MIFID_TEST_STOCKS_ID: '22',
    BROKER_CONVENIENCE_MIFID_TEST_THIRD_STEP_DERIVATIVES_OTC_QUESTION_ID: '189',
    BROKER_CONVENIENCE_MIFID_TEST_THIRD_STEP_DERIVATIVES_OTC_TWO_OR_MORE_ANSWER: '190',
    BROKER_CONVENIENCE_MIFID_TEST_THIRD_STEP_FIXED_INCOME_WITH_IMPLIED_DERIVATIVES_QUESTION_ID: '173',
    BROKER_CONVENIENCE_MIFID_TEST_THIRD_STEP_FIXED_INCOME_WITH_IMPLIED_DERIVATIVES_TWO_OR_MORE_ANSWER: '174',
    BROKER_CONVENIENCE_MIFID_TEST_WARRANTS_ID: '1',
    BROKER_CONVENIENCE_MIFID_WARRANT: 'BROKER_CONVINIENCE_MIFID_WARRANT',
    BROKER_CONVENIENCE_MIFID_WARRANT_BUY: 'BROKER_CONVENIENCE_MIFID_WARRANT_BUY',
    BROKER_CORPORATE_OPEATIONS_MIFID_ID: 4,
    BROKER_CORPORATE_OPERATIONS_BUY_RIGHTS_URL: '/myprofile/broker/rightsBuy',
    BROKER_CORPORATE_OPERATIONS_CANJE_PROCESS_TYPE: '543',
    BROKER_CORPORATE_OPERATIONS_CANJE_PRODUCT_TYPE: 'Canje',
    BROKER_CORPORATE_OPERATIONS_CASH_EXCHANGE_PRODUCT_TYPE: 'Cobro en Efectivo',
    BROKER_CORPORATE_OPERATIONS_CHANGE_URL: '/myprofile/broker/request-financial-operation-change/wizard',
    BROKER_CORPORATE_OPERATIONS_COMPRA_ACCIONES_PROCESS_TYPE: '066',
    BROKER_CORPORATE_OPERATIONS_COMPRA_ACCIONES_PRODUCT_TYPE: 'Opv',
    BROKER_CORPORATE_OPERATIONS_DERECHOS_PROCESS_TYPE: '000',
    BROKER_CORPORATE_OPERATIONS_EVENT_CODE_AMPLIACION_CAPITAL: 'A',
    BROKER_CORPORATE_OPERATIONS_EVENT_CODE_CANJE: 'C',
    BROKER_CORPORATE_OPERATIONS_RIGHTS_BUY_ORDER_CONDITION: 'A MERCADO',
    BROKER_CORPORATE_OPERATIONS_RIGHTS_PRODUCT_TYPE: 'Derechos',
    BROKER_CORPORATE_OPERATIONS_RIGHTS_SUBPROCESS_DESCRIPTION: 'DERECHOS',
    BROKER_CORPORATE_OPERATIONS_SUBSCRIPTION_PRODUCT_TYPE: 'Suscripción',
    BROKER_CORPORATE_OPERATIONS_URL: '/myprofile/broker/corporatesOperations',
    BROKER_CORPORATE_OPERATIONS_VENTA_ACCIONES_PROCESS_TYPE: '566',
    BROKER_CORPORATE_OPERATIONS_VENTA_ACCIONES_PRODUCT_TYPE: 'Opa',
    BROKER_CORPORATE_OPERATION_CASH_EXCHANGE_RIGHTS_URL: '/myprofile/broker/rightsCashExchange',
    BROKER_CORPOTATE_OPERATIONS_PURCHASE_ORDER_URL: '/myprofile/broker/stocks-purchase-order/wizard',
    BROKER_CO_SCREEN: 'CO',
    BROKER_CREATE_TRANSFER_OF_DERIVATIVE_VALID_PRODUCT_SUBTYPE: '804',
    BROKER_CREATE_TRANSFER_OF_DERIVATIVE_VALID_PRODUCT_TYPE: '300',
    BROKER_CUSTOM_CONFIG_WALLET_GENERAL_NAME: 'Total',
    BROKER_CUSTOM_CONFIG_WALLET_NAME_LIMIT: 38,
    BROKER_CUSTOM_WALLET_CONFIGURATION_URL: '/myprofile/broker/custom-portfolio',
    BROKER_CUSTOM_WALLET_CONTRACT_FUNDS_TYPE: 'funds',
    BROKER_CUSTOM_WALLET_CONTRACT_PLANS_TYPE: 'plans',
    BROKER_CUSTOM_WALLET_CONTRACT_VALORES_TYPE: 'valores',
    BROKER_CUSTOM_WALLET_CREATE_ACCOUNT_ID: 0,
    BROKER_CUSTOM_WALLET_CREATE_INPUT_LIMIT: "50",
    BROKER_CUSTOM_WALLET_GENERAL_VIEW: '0',
    BROKER_CUSTOM_WALLET_INVESTMENTS: '/myprofile/broker',
    BROKER_CUSTOM_WALLET_MAX_ON_CREATE: "Can't to create more than 15 portfolios by user.",
    BROKER_CUSTOM_WALLET_MY_WALLET_CREATE_NEW_VIEW: '/myprofile/broker/custom-portfolio/create',
    BROKER_CUSTOM_WALLET_PG_VIEW: 'WEALTH_CUSTOM_PG_VIEW',
    BROKER_CUSTOM_WALLET_PORTFOLIO_ACCOUNT_TYPE: 3,
    BROKER_CUSTOM_WALLET_PORTFOLIO_ACCOUNT_TYPE_GENERAL: 1,
    BROKER_DAILY_LOWERING_PERCENTAGE_GRATER_THAN_CONDITION_ID: 'VMN',
    BROKER_DAILY_RACING_PERCENTAGE_GRATER_THAN_CONDITION_ID: 'VMY',
    BROKER_DAX30_STOCK_MARKET_NAME: 'DAX 30',
    BROKER_DEFAULT_CONDITION_TYPE_ID: 'CMY',
    BROKER_DEFAULT_CONDITION_TYPE_VALUE: 0,
    BROKER_DEFAULT_STOCK_ID: -1,
    BROKER_DEFAULT_STOCK_MARKET_INDEX: -1,
    BROKER_DERIVATIVES_MOVEMENTS_CREATE_SIGNATURE_PATTERN: 95,
    BROKER_DERIVATIVES_MOVEMENTS_UPDATE_SIGNATURE_PATTERN: 96,
    BROKER_DOCUMENTATION_URL: '/myprofile/documentation',
    BROKER_DOCUMENT_TYPE: 'costYieldTemplate',
    BROKER_DOCUMENT_TYPE_SELL: 'validationLEITemplate',
    BROKER_EMPLOYEE_ID: 111111,
    BROKER_EMPTY_PRODUCTS: 0,
    BROKER_ETFS_GS_BREAKDOWNS_BASIC_MATERIALS_TYPE: '101',
    BROKER_ETFS_GS_BREAKDOWNS_COMMUNCATION_SERVICES_TYPE: '308',
    BROKER_ETFS_GS_BREAKDOWNS_CONSUMER_CYCLICAL_TYPE: '102',
    BROKER_ETFS_GS_BREAKDOWNS_CONSUMER_DEFENSIVE_TYPE: '205',
    BROKER_ETFS_GS_BREAKDOWNS_ENERGY_TYPE: '309',
    BROKER_ETFS_GS_BREAKDOWNS_FINANCIAL_SERVICES_TYPE: '103',
    BROKER_ETFS_GS_BREAKDOWNS_HEALTHCARE_TYPE: '206',
    BROKER_ETFS_GS_BREAKDOWNS_INDUSTRIALS_TYPE: '310',
    BROKER_ETFS_GS_BREAKDOWNS_REAL_STATE_TYPE: '104',
    BROKER_ETFS_GS_BREAKDOWNS_TECHNOLOGY_TYPE: '311',
    BROKER_ETFS_GS_BREAKDOWNS_UTILITIES_TYPE: '207',
    BROKER_ETFS_HELP_URL: 'https://bancoonline.openbank.es/csopen/StaticBS?blobcol=urldata&blobheader=application%2Fpdf&blobkey=id&blobtable=MungoBlobs&blobwhere=1287277093005&cachecontrol=immediate&ssbinary=true&maxage=3600',
    BROKER_ETFS_PRODUCT_STARS_TOTAL: 5,
    BROKER_ETFS_RESUME_DOCS_BIANNUAL_TYPE: '5',
    BROKER_ETFS_RESUME_DOCS_COMMERCIALIZATION_TYPE: '4',
    BROKER_ETFS_RESUME_DOCS_DFI_TYPE: '74',
    BROKER_ETFS_SELL_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_ETFS_SELL_ORDER_TYPE_VALUE_DEFAULT: 1,
    BROKER_ETFS_SELL_TITLE_QUANTITY_VALUE_DEFAULT: 1,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_CASH: '3',
    BROKER_ETFS_WALLET_ACTIVES_TYPE_LONG: 'L',
    BROKER_ETFS_WALLET_ACTIVES_TYPE_NET: 'N',
    BROKER_ETFS_WALLET_ACTIVES_TYPE_OBLIGATIONS: '2',
    BROKER_ETFS_WALLET_ACTIVES_TYPE_OTHERS: '99',
    BROKER_ETFS_WALLET_ACTIVES_TYPE_SHORT: 'S',
    BROKER_ETFS_WALLET_ACTIVES_TYPE_STOCKS: '1',
    BROKER_ETF_BUY_BUYFOR_AMOUNT: 'amount',
    BROKER_ETF_BUY_BUYFOR_TITLES: 'titles',
    BROKER_ETF_BUY_BUYFOR_VALUE_DEFAULT: null,
    BROKER_ETF_BUY_ORDER_TYPE_VALUE_DEFAULT: 1,
    BROKER_ETF_BUY_STEP1_MAX_IMPORT_DIGITS: 9,
    BROKER_ETF_BUY_STEP1_MAX_TITLE_DIGITS: 8,
    BROKER_EXCHANGE_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION: 'LEAFLET_SUSBCRIPTION',
    BROKER_EXCHANGE_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION: 'OFFICIAL_COMMUNICATION',
    BROKER_EXTERNAL_SUSCRIPTION_CODE: 'FSR',
    BROKER_FAVORITE_LIST_SIZE: 3,
    BROKER_FAVORITE_MAX_LIST_SIZE: 10,
    BROKER_FAVORITE_MAX_PRODUCT_SIZE: 25,
    BROKER_FAVORITE_START_PAGE_NUM: 1,
    BROKER_FILTERS_EMPTY_STATE: 'Filters',
    BROKER_FUNDS_AMOUNT_DECIMALS: 2,
    BROKER_FUNDS_EXTERNAL_TRANSFER_PARTICIPATIONS_INTEGER_LENGTH: 9,
    BROKER_FUNDS_EXTERNAL_TRANSFER_PARTICIPATIONS_MAXIMUM_VALUE: 999999999.99999,
    BROKER_FUNDS_EXTERNAL_TRANSFER_PARTICIPATIONS_MINIMUM_VALUE: 0.00001,
    BROKER_FUNDS_INDEX_VARIATION_DECIMALS: 3,
    BROKER_FUNDS_INTERNAL_TRANSFER_AMOUNT_INTEGER_LENGTH: 10,
    BROKER_FUNDS_INTERNAL_TRANSFER_AMOUNT_MAXIMUM_VALUE: 9999999999.99,
    BROKER_FUNDS_INTERNAL_TRANSFER_AMOUNT_MINIMUM_VALUE: 0.01,
    BROKER_FUNDS_INTERNAL_TRANSFER_CONTRACT_FORMAT: [4, 4, 2],
    BROKER_FUNDS_INTERNAL_TRANSFER_PARTICIPATIONS_INTEGER_LENGTH: 9,
    BROKER_FUNDS_INTERNAL_TRANSFER_PARTICIPATIONS_MAXIMUM_VALUE: 999999999.99999,
    BROKER_FUNDS_INTERNAL_TRANSFER_PARTICIPATIONS_MINIMUM_VALUE: 0.00001,
    BROKER_FUNDS_INTERNAL_TRANSFER_PERCENTAGE_INTEGER_LENGTH: 3,
    BROKER_FUNDS_INTERNAL_TRANSFER_PERCENTAGE_MAXIMUM_VALUE: 100.00,
    BROKER_FUNDS_INTERNAL_TRANSFER_PERCENTAGE_MINIMUM_VALUE: 0.01,
    BROKER_FUNDS_INTERNAL_TRANSFER_CUSTOM_CATEGORY_ID3_NO_VISIBLE: '0',
    BROKER_FUNDS_NET_ASSET_DAY_END_VALUE_DECIMALS: 3,
    BROKER_FUNDS_PARTICIPATIONS_DECIMALS: 5,
    BROKER_FUNDS_PARTIAL_AMOUNT_DECIMALS: 2,
    BROKER_FUNDS_PERCENTAGE_DECIMALS: 2,
    BROKER_FUNDS_RATING: 5,
    BROKER_FUNDS_REIMBURSE_CONTRACT_FORMAT: [4, 4, 2],
    BROKER_FUNDS_SUBSCRIPTION_CONTRACT_FORMAT: [4, 4, 2],
    BROKER_FUND_LEGAL_DOCUMENT_DFI: 'DFI',
    BROKER_FUND_LEGAL_DOCUMENT_DOCUMENT_TYPE_FUNDAMENTAL_DATA_INVESTOR: '74',
    BROKER_FUND_LEGAL_DOCUMENT_DOCUMENT_TYPE_LAST_ECONOMIC_REPORT: '5',
    BROKER_FUND_LEGAL_DOCUMENT_DOCUMENT_TYPE_SEMI_ANNUAL_REPORT: '5',
    BROKER_FUND_LEGAL_DOCUMENT_MORNING_STAREXT_SECURTY_DETAILS: '7ug1nla4nl',
    BROKER_FUND_LEGAL_DOCUMENT_SEMIANUAL_REPORT: 'InformeSemestral',
    BROKER_FUND_MIFID_ID: 2,
    BROKER_FUND_NEW_CONTRACT_OPTION: 'newContract',
    BROKER_FUTURES_ACCESS_PLATFORM_DERIVATIVE_ACCOUNT: 'CUENTA DE DERIVADOS',
    BROKER_FUTURES_ACCESS_PLATFORM_SIGNATURE_PATTERN_ID: 84,
    BROKER_FUTURES_ACCESS_PLATFORM_SIGNATURE_PATTERN_PARAM: '00730100',
    BROKER_FUTURES_HELP_URL: 'https://bancoonline.openbank.es/csopen/StaticBS?blobcol=urldata&blobheader=application%2Fpdf&blobkey=id&blobtable=MungoBlobs&blobwhere=1287277106907&cachecontrol=immediate&ssbinary=true&maxage=3600',
    BROKER_FUTURES_PLATFORM_ACCESS_GARANTY_DOWNLOAD: '/assets/static/pdf/Inversiones/GARANTIAS%20%2004-07-17-%20MEFF.pdf',
    BROKER_FUTURES_TRASPASS_FRACTION_LENGTH: 2,
    BROKER_FUTURES_TRASPASS_MAXIMUM_LENGTH: 13,
    BROKER_FUTURES_TRASPASS_MAXIMUM_VALUE: 9999999999999.99,
    BROKER_GENERAL_CALENDAR_MAX_EVENTS: 2,
    BROKER_GENERAL_VIEW_NAME: 'Mi Cartera',
    BROKER_GP_SCREEN: 'GP',
    BROKER_GREEN_FUND: '1',
    BROKER_HELP_LINK: '',
    BROKER_HIRE_DERIVATIVES_ACCOUNT_SIGNATURE_PATTERN_TYPE: 70,
    BROKER_HIRE_INVESTMENT_STEP_2_NO: false,
    BROKER_HIRE_INVESTMENT_STEP_2_YES: true,
    BROKER_HIRE_PENSION_PLAN_ANNUAL: 'A',
    BROKER_HIRE_PENSION_PLAN_ANNUAL_INCREMENT_IPC: 3,
    BROKER_HIRE_PENSION_PLAN_ANNUAL_INCREMENT_PERCENTAGE: 1,
    BROKER_HIRE_PENSION_PLAN_CATEGORY: 'PLANES',
    BROKER_HIRE_PENSION_PLAN_SUBCATEGORY: 'plan',
    BROKER_HIRE_PENSION_PLAN_CONTRIBUTION: '001',
    BROKER_HIRE_PENSION_PLAN_DESIGNATION_DOCUMENT_URL: 'https://www.openbank.es/assets/static/pdf/planes/designacionBeneficiarios.pdf',
    BROKER_HIRE_PENSION_PLAN_EMPTY_DATA: '             ',
    BROKER_HIRE_PENSION_PLAN_ERROR_EXTRAORDINARY_CONTRIB: 'aportación extraordinaria',
    BROKER_HIRE_PENSION_PLAN_ERROR_PERIODIC_CONTRIB: 'aportaciones periódicas',
    BROKER_HIRE_PENSION_PLAN_ERROR_SECONDARY: 'secondary',
    BROKER_HIRE_PENSION_PLAN_ERROR_TO_EXTRAORDINARY_CONTRIB: '/myprofile/broker/pensionPlansContribution/',
    BROKER_HIRE_PENSION_PLAN_ERROR_TO_PERIODIC_CONTRIB: '/myprofile/broker/pensionPlansPeriodicContribution/',
    BROKER_HIRE_PENSION_PLAN_ERROR_WHITE: 'white',
    BROKER_HIRE_PENSION_PLAN_HAS_PERIODICAL_CONTRIB: 2,
    BROKER_HIRE_PENSION_PLAN_IS_IN_WALLET: 1,
    BROKER_HIRE_PENSION_PLAN_MONTHLY: 'MENSUAL',
    BROKER_HIRE_PENSION_PLAN_NO_PERIODICITY: 'initBlankOption',
    BROKER_HIRE_PENSION_PLAN_PERIODIC_CONTRIBUTION: '002',
    BROKER_HIRE_PENSION_PLAN_QUATERLY: 'T',
    BROKER_HIRE_PENSION_PLAN_SUB_CATEGORY: 'boletinAdhesion',
    BROKER_HIRE_PENSION_PLAN_URL: '/myprofile/broker/buyPensionPlan/',
    BROKER_HIRE_PENSION_PLAN_WEEKLY: 'S',
    BROKER_HIRE_PLAN_FORMAT_MAIL: /[^a-zA-Z0-9_]+/g,
    BROKER_HOLIDAY_VALIDATION_ERROR: 'VB_3005',
    BROKER_IBEX35_STOCK_MARKET_INDEX: 'ibex35',
    BROKER_IBEX35_STOCK_MARKET_NAME: 'IBEX 35',
    BROKER_INDEXES_FIRST_INDEX_POSITION: 0,
    BROKER_INDEXES_SPC_SYMBOL: 'OB_Home_MyN_Indices',
    BROKER_INDEX_CATEGORY_SYMBOL: 'IND',
    BROKER_INDEX_ORDER_CONDITION_VALUE: 'index',
    BROKER_INFO_SCREEN: 'INFO',
    BROKER_INPUT_SEARCH_ALLPRODUCTS_MODIFIER: 'allproducts',
    BROKER_INPUT_SEARCH_ETFS_TYPE: 'etfs',
    BROKER_INPUT_SEARCH_FUNDS_TYPE: 'funds',
    BROKER_INPUT_SEARCH_ONLWARRANTS_MODIFIER: 'onlywarrants',
    BROKER_INPUT_SEARCH_ONLYETFS_MODIFIER: 'onlyetfs',
    BROKER_INPUT_SEARCH_ONLYFUNDS_MODIFIER: 'onlyfunds',
    BROKER_INPUT_SEARCH_ONLYSTOCKS_MODIFIER: 'onlystocks',
    BROKER_INPUT_SEARCH_STOCKS_TYPE: 'stocks',
    BROKER_INPUT_SEARCH_WARRANTS_TYPE: 'warrants',
    BROKER_INPUT_SEARCH_WIDGET_PRODUCT: 'widgetsearchproduct',
    BROKER_INTERNAL_SUSCRIPTION_CODE: 'FSI',
    BROKER_INTERNAL_TRANSFER_EXISTING_FUND: 'existing',
    BROKER_INTERNAL_TRANSFER_NEW_FUND: 'new',
    BROKER_INTERNAL_TRANSFER_FUND_NO_RESULT_ERROR: 'No se encontraron resultados',
    BROKER_INTERNAL_TRANSFER_FUND_TOTAL_RESULT_ZERO: '0',
    BROKER_INTRADAY_DOWN_VARIATION_GRATER_THAN_CONDITION_ID: 'BDP',
    BROKER_INTRADAY_DOWN_VARIATION_PERCENTAGE_GRATER_THAN_CONDITION_ID: 'BDV',
    BROKER_INTRADAY_UP_VARIATION_GRATER_THAN_CONDITION_ID: 'SDP',
    BROKER_INTRADAY_UP_VARIATION_PERCENTAGE_GRATER_THAN_CONDITION_ID: 'SDV',
    BROKER_INVESTMENT_FUNDS_PRODUCT_URL_PARAMETER: '7ug1nla4nl',
    BROKER_CONTRIBUTION_FUNDS_PRODUCT_URL_PARAMETER: '7ug1nla4nl',
    BROKER_INVESTMENT_FUNDS_URL_FILTER_DATA_POINTS: 'SecId|ISIN|Name|CustomCategoryIdName|Rating|CustomRisk|ReturnM0_ME|ReturnM0|ReturnM12|ReturnM36|ReturnM60|CustomMinimumPurchaseAmount|CustomMinimumPurchaseAmountUnit|CustomExternalURL1|CustomExternalURL2|CustomInstitutionSecurityId',
    BROKER_INVESTMENT_PLANS_PRODUCT_URL_PARAMETER: 'uk108sl6ii',
    BROKER_INVESTMENT_SPECIALIST_EMAIL: 'inversiones@openbank.es',
    BROKER_JOINT_CONTRACTS_FUND: 'FONDO',
    BROKER_JOINT_CONTRACTS_FUTURES: 'FUTURO',
    BROKER_JOINT_CONTRACTS_PLAN: 'PLAN',
    BROKER_JOINT_CONTRACTS_VALUE: 'VALOR',
    BROKER_LANDING_PG_PLACE: 'BROKER_LANDING_PG_PLACE',
    BROKER_LANDING_PLANS_PLACE: 'BROKER_LANDING_PLANS_PLACE',
    BROKER_LARGE_FLAG_SIZE: 20,
    BROKER_LAST_MIFID_DAY: '2018-01-02',
    BROKER_LEGAL_DOCUMENTATION_ANNUAL_REPORT: 'ANNUAL_ECONOMIC_REPORT',
    BROKER_LEGAL_DOCUMENTATION_DFI: 'DFI',
    BROKER_LEGAL_DOCUMENTATION_ECONOMIC_REPORT: 'ECONOMIC_REPORT',
    BROKER_LEGAL_DOCUMENTATION_MARKETING_REPORT: 'MARKETING_REPORT',
    BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST: 'COST',
    BROKER_LEGAL_DOCUMENTATION_PROSPECTUS_COST: 'PROSPECTUS',
    BROKER_LEGAL_DOCUMENTATION_DEFAULT_INDEX: -1,
    BROKER_LEGAL_ALT_DOCUMENTATION_RECOMMENDATION_REPORT: 'RECOMMENDATION_REPORT',
    BROKER_LEGAL_ALT_DOCUMENTATION_RISK_ASSUMPTION: 'RISKASSUMPTION_DOCUMENT',
    BROKER_LEGAL_ALT_DOCUMENTATION_DFI: 'DFI',
    BROKER_LEGAL_ALT_DOCUMENTATION_PROSPECUTS: 'PROSPECTUS',
    BROKER_LEGAL_ALT_DOCUMENTATION_ECONOMIC_REPORT: 'ECONOMIC_REPORT',
    BROKER_LIST_SCREEN: 'LIST',
    BROKER_LOGIN_URL: '/login',
    BROKER_MAIN_STOCKS_BY_PERCENTAGE: 1,
    BROKER_MAIN_STOCKS_BY_VOLUME: 2,
    BROKER_MAIN_STOCKS_FALLERS: 0,
    BROKER_MAIN_STOCKS_RISERS: 1,
    BROKER_MAIN_STOCKS_TAB_FALLERS: 1,
    BROKER_MAIN_STOCKS_TAB_RISERS: 0,
    BROKER_MAIN_STOCKS_TAB_VOLUME: 2,
    BROKER_MAIN_STOCKS_VIEW_MAX_ROWS: 20,
    BROKER_MARKET_BARCELONA: 'BARCELONA - MERCADO CONTINUO',
    BROKER_MARKET_CALENDAR_MAX_EVENTS: 5,
    BROKER_MARKET_EMPTY: '- MERCADO CONTINUO',
    BROKER_MARKET_INDEXES_EXCLUDED_INDEX: 0,
    BROKER_MARKET_MADRID: 'MADRID - MERCADO CONTINUO',
    BROKER_MAX_MORE_NEWS: 4,
    BROKER_MAX_MORE_PULSES: 4,
    BROKER_MIFID_2_DISCLAIMER_ACTION: 'BROKER_MIFID_2_DISCLAIMER_ACTION',
    BROKER_MIFID_ACTION_MANUAL_TEST: 'BROKER_MIFID_MANUAL_TEST',
    BROKER_MIFID_ALLOW_DISCLAIMER_ACCEPTING_RESPONSABILITY: '1',
    BROKER_MIFID_ALLOW_DISCLAIMER_CONTINUE: '0',
    BROKER_MIFID_ALLOW_DISCLAIMER_REPEAT_SENTENCE: '2',
    BROKER_MIFID_ANSWER_ACTION: 'A',
    BROKER_MIFID_DEFAULT_PDF_AMOUNT_DECIMALS: 2,
    BROKER_MIFID_DISPLAYED_FUTURE_ACCESS_TO_PLATFORM_ID: 'platform-access',
    BROKER_MIFID_ETF_PDF_AMOUNT_DECIMALS: 2,
    BROKER_MIFID_FUNDS_TRANSFER_AMOUNT_DECIMALS: 2,
    BROKER_MIFID_FUNDS_TRANSFER_SHARES_DECIMALS: 5,
    BROKER_MIFID_FUND_GREEN: '8',
    BROKER_MIFID_FUND_RED: '2',
    BROKER_MIFID_FUND_YELLOW: '7',
    BROKER_MIFID_FUTURES_ID: 3,
    BROKER_MIFID_NOT_ALLOWED: '3',
    BROKER_MIFID_REJECT_ACTION: 'R',
    BROKER_MIFID_REQUIRED_SCORE_ETF_INT_ID: '18',
    BROKER_MIFID_REQUIRED_SCORE_WARRANT_ID: '1',
    BROKER_MIFID_RIGHTS_PDF_AMOUNT_DECIMALS: 2,
    BROKER_MIFID_STOCK_PDF_AMOUNT_DECIMALS: 2,
    BROKER_MIFID_VERSION_1: '1',
    BROKER_MIFID_VERSION_2: '2',
    BROKER_MOST_POPULAR_OF_MONTH_DEFAULT_TAB: 0,
    BROKER_MOST_POPULAR_OF_MONTH_FUNDS_TAB: 0,
    BROKER_MOST_POPULAR_OF_MONTH_MAX_ROWS: 5,
    BROKER_MOST_POPULAR_OF_MONTH_MAX_STARTS: 5,
    BROKER_MOST_POPULAR_OF_MONTH_PLANS_TAB: 1,
    BROKER_MYPROFILE_ETFS_BUY_URL: '/myprofile/broker/etfsBuy/',
    BROKER_MYPROFILE_FUNDS_BUY_URL: '/myprofile/broker/buyInvestmentFunds/',
    BROKER_MYPROFILE_FUND_FILE: '/myprofile/broker/fund/isin', // route defined in ok-funds-card dependency
    BROKER_MYPROFILE_MYWALLET_URL: '/myprofile/broker',
    BROKER_MYPROFILE_PRODUCT_FILE: '/myprofile/broker/productFile',
    BROKER_MYPROFILE_PRODUCT_FILE_CAPITALIZE: 'ProductFile',
    BROKER_MYPROFILE_STOCKS_BUY_URL: '/myprofile/broker/stocksBuy/',
    BROKER_MYPROFILE_WARRANTS_BUY_URL: '/myprofile/broker/warrantsBuy/',
    BROKER_NATIONAL_ID_DOC_TYPE: 'validationLEITemplate',
    BROKER_NEWS_LIMIT: 20,
    BROKER_NEWS_MAX_NEWS_LIMIT: 3,
    BROKER_NEWS_MAX_SHOW_LIMIT: 5,
    BROKER_NEWS_TRUNCATE_CHAR_LIMIT: 40,
    BROKER_NEWS_TRUNCATE_LINES: 3,
    BROKER_NEWS_TRUNCATE_LINES_NEWS_VIEW: 5,
    BROKER_NON_NATIONAL_IDS_VALIDATION_TYPES: ['funds', 'futures', 'plans', 'others', 'FONDOS_ALTERNATIVOS'],
    BROKER_NO_STOCKS_ACCOUNT_SELECTED: -1,
    BROKER_NO_STOCKS_CONTRACT_SELECTED: -1,
    BROKER_OPA_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION: 'LEAFLET_SUSBCRIPTION',
    BROKER_OPA_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION: 'OFFICIAL_COMMUNICATION',
    BROKER_OPV_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION: 'LEAFLET_SUSBCRIPTION',
    BROKER_OPV_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION: 'OFFICIAL_COMMUNICATION',
    BROKER_ORDERS_AND_MOVEMENTS_BUY_OPERATION: 'COMPRA',
    BROKER_ORDERS_AND_MOVEMENTS_BUY_OPERATION_CONDITIONAL: 'Compra condicionada',
    BROKER_ORDERS_AND_MOVEMENTS_FILTERS_BOX_DEFAULT_TAB: 0,
    BROKER_ORDERS_AND_MOVEMENTS_FILTERS_FROM_DATE: 'fromDate',
    BROKER_ORDERS_AND_MOVEMENTS_FILTERS_ORDERS_SELECTED_STATE: 'selectedOrderState',
    BROKER_ORDERS_AND_MOVEMENTS_FILTERS_ORDERS_SELECTED_TYPE: 'selectedOrderType',
    BROKER_ORDERS_AND_MOVEMENTS_FILTERS_ORDERS_VALUE: 'ordersValue',
    BROKER_ORDERS_AND_MOVEMENTS_FILTERS_TO_DATE: 'toDate',
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_CONSOLIDATED: 'CONSOLIDADO',
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_NULLED: 'ANULADO',
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_PENDING: 'PENDIENTE',
    BROKER_ORDERS_AND_MOVEMENTS_PERIODIC_CONTRIBUTION_ANNUAL: 12,
    BROKER_ORDERS_AND_MOVEMENTS_PERIODIC_CONTRIBUTION_MONTHLY: 1,
    BROKER_ORDERS_AND_MOVEMENTS_PERIODIC_CONTRIBUTION_QUARTERLY: 3,
    BROKER_ORDERS_AND_MOVEMENTS_PERIODIC_CONTRIBUTION_SEMESTER: 6,
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_CONTRIBUTION_DEFAULT_LAST_DATE: '0001-01-01',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_CONTRIBUTION_NO_NEXT_DATE: '9999-12-01',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_EXTRAORDINARY: 'APORTACION EXTRAORDINARIA',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_OPERATION_SELECTED: "PSE",
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_OTHER: 'OTRA OPERACION',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_OTHER_INITIAL_DATE: '2000-01-01',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_PENDING_MOVES: 'MOVIMIENTOS PENDIENTES',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_PERIODIC: 'APORTACION PERIODICA',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_PERIODIC_NO_RESULTS: 'BROKER_ORDERS_AND_MOVEMENTS_PLAN_PERIODIC_NO_RESULTS',
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_REJECTED: 'REJECTED PLAN',
    BROKER_ORDERS_AND_MOVEMENTS_SALE_OPERATION: 'VENTA',
    BROKER_ORDERS_AND_MOVEMENTS_SALE_OPERATION_CONDITIONAL: 'Venta condicionada',
    BROKER_ORDER_CONDITION_TYPE_VALUE_EUR_UNIT: 'EUR',
    BROKER_ORDER_CONDITION_TYPE_VALUE_PERCENTAGE_UNIT: '%',
    BROKER_ORDER_STATE_ANULATED: 'ANULADA',
    BROKER_ORDER_STATE_EXECUTED: 'EJECUTADA',
    BROKER_ORDER_STATE_MARKET: 'LIQ. MERC',
    BROKER_ORDER_STATE_PARCIALY: 'PARC.EJEC',
    BROKER_ORDER_STATE_PENDING: 'PENDIENTE',
    BROKER_ORDER_TYPE_BUY: 'Compra',
    BROKER_ORDER_TYPE_CASH_EXCHANGE: 'Cobro',
    BROKER_ORDER_TYPE_CONDITION_TITLE_AMOUNT: 'titleAmount',
    BROKER_ORDER_TYPE_EXCHANGE: 'Canje',
    BROKER_ORDER_TYPE_FOR_THE_BEST: 'forTheBest',
    BROKER_ORDER_TYPE_FOR_THE_BEST_DESCRIPTION: 'POR LO MEJOR',
    BROKER_ORDER_TYPE_LIMITED: 'limited',
    BROKER_ORDER_TYPE_LIMITED_DESCRIPTION: 'LIMITADA',
    BROKER_ORDER_TYPE_SELL: 'Venta',
    BROKER_ORDER_TYPE_SUBSCRIPTION: 'Suscripcion',
    BROKER_ORDER_TYPE_TO_MARKET: 'toMarket',
    BROKER_ORDER_TYPE_TO_MARKET_DESCRIPTION: 'A MERCADO',
    BROKER_ORIGIN_CONTRACT_NUMBER_MAX_LENGTH: 20,
    BROKER_OUR_METHODOLOGY_URL: 'http://corporate.morningstar.com/uk/documents/MethodologyDocuments/MethodologyPapers/MorningstarFundRating_Methodology.pdf',
    BROKER_PARTIAL_TRANSFER_TYPE_FUNDS_AMOUNT: 'amount',
    BROKER_PARTIAL_TRANSFER_TYPE_FUNDS_PARTICIPATIONS: 'participations',
    BROKER_PARTIAL_TRANSFER_TYPE_FUNDS_PERCENTAGE: 'percentage',
    BROKER_PENSIONS_RATING: 5,
    BROKER_PENSION_PRICE_DECIMALS: 3,
    BROKER_PERIODIC_ACTIVE_CONTRIBUTION: 'ACTIVO',
    BROKER_PG_ROBO_URL: '/myprofile/broker/general/robo',
    BROKER_PG_URL: '/myprofile/broker/general',
    BROKER_PLANS_DOCUMENTS_CATEGORY: 'PLANES DE PENSIONES',
    BROKER_PLANS_HELP_URL: 'https://bancoonline.openbank.es/csopen/StaticBS?blobcol=urldata&blobheader=application%2Fpdf&blobkey=id&blobtable=MungoBlobs&blobwhere=1287277106920&cachecontrol=immediate&ssbinary=true&maxage=3600',
    BROKER_PLANS_PRODUCT_URL_PARAMETER: 'uk108sl6ii',
    BROKER_PLANS_TRANSFER_DOCUMENTS_SUBCATEGORY: 'TRASPASO ENTRE PLANES',
    BROKER_PLAN_LEGAL_DOCUMENT_BULLETIN: 'ADHESION_BULLETIN',
    BROKER_PLAN_LEGAL_DOCUMENT_DDFP: 'DDFP',
    BROKER_PLAN_LEGAL_DOCUMENT_FUNDAMENTAL_PLAN_DATA: '74',
    BROKER_PLAN_LEGAL_DOCUMENT_MORNING_STAREXT_SECURTY_DETAILS: 'uk108sl6ii',
    BROKER_PLAN_LEGAL_DOCUMENT_SERVICE_PROVISION: 'SERVICES_CONTRACT',
    BROKER_PLAN_LEGAL_DOCUMENT_SPECIFICATIONS: 'SPECIFICATIONS',
    BROKER_PLAN_LEGAL_DOCUMENT_SPECIFICATIONS_DOCUMENT: '51',
    BROKER_PLAN_PERIODICAL_CONTRIBUTION_ACTIVE_CONTRIBUTION: 'AC',
    BROKER_PLAN_PERIODICAL_CONTRIBUTION_SEGUN_IPC: 'SEGUN_IPC',
    BROKER_PRICE_GRATER_OR_EQUAL_CONDITION_ID: 'CMY',
    BROKER_PRICE_LESS_OR_EQUAL_CONDITION_ID: 'CMN',
    BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS: '0',
    BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_SUB_ASSET_TYPES: 'assetClassWithSubClassValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES: 'assetClassValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY: 'currencyValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER: 'managerValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_MARKET: 'marketValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_MORE_RESULTS: 5,
    BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES: 'productCategoryValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_RISK: 'riskValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE: 'subAssetClassValues',
    BROKER_PRODUCT_ADVANCED_SEARCH_TERM: 'term',
    BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH: 37,
    BROKER_PRODUCT_SEARCH_GROUP_LIMIT: 10,
    BROKER_PRODUCT_SEARCH_OPTIONS_LIMIT: 5,
    BROKER_PRODUCT_SEARCH_OPTIONS_LIMIT_10: 10,
    BROKER_PRODUCT_SEARCH_OPTIONS_LIMIT_3: 3,
    BROKER_PRODUCT_TYPE_ACCIONES: 'ACCIONES',
    BROKER_PRODUCT_TYPE_ASSOCIATED: 'associated-derechos',
    BROKER_PRODUCT_TYPE_CAPITAL_INCREASE: 'AMPLIACION_CAPITAL',
    BROKER_PRODUCT_TYPE_DERECHOS: 'DERECHOS',
    BROKER_PRODUCT_TYPE_DERECHOS_ID: '099',
    BROKER_PRODUCT_TYPE_ETFS_ES: 'ETFs',
    BROKER_PRODUCT_TYPE_EXCHANGE: 'CANJE',
    BROKER_PRODUCT_TYPE_FUNDS: 'FONDOS',
    BROKER_PRODUCT_TYPE_ALTERNATIVE_FUNDS: 'FONDOS_ALTERNATIVOS',
    BROKER_PRODUCT_TYPE_FUNDS_ES: 'Fondos',
    BROKER_PRODUCT_TYPE_LEGAL_WARRANTS: 'WARRANTS',
    BROKER_PRODUCT_TYPE_OPA: 'OPA',
    BROKER_PRODUCT_TYPE_OPV: 'OPV',
    BROKER_PRODUCT_TYPE_PLANS: 'PLANES',
    BROKER_PRODUCT_TYPE_PLANS_ES: 'Planes', // remove on "new search feature"
    BROKER_PRODUCT_TYPE_STANDALONE: 'standalone',
    BROKER_PRODUCT_TYPE_STOCKS: 'Acciones',
    BROKER_PRODUCT_TYPE_STOCKS_WITH_RIGHTS: 'stocks-with-derechos',
    BROKER_PRODUCT_TYPE_WARRANTS: 'Warrants',
    BROKER_PSI20_STOCK_MARKET_NAME: 'PSI 20',
    BROKER_RED_FUND: '2',
    BROKER_REIMBURSE_FUND_PARTIAL: 'partial',
    BROKER_REIMBURSE_FUND_TOTAL: 'total',
    BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_DISCRIMINADOR_CLAUSULA: 'N',
    BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SIGNATURE_PATTERN_OPERATION_TYPE: 39,
    BROKER_RESTRICTED_ORDER_TERM: 'restricted',
    BROKER_RIGHTS_BUY_CORPORATE_OPERATION: 'C',
    BROKER_RIGHTS_BUY_FIELDS_MAXLENGHT_LIMIT: 999999999,
    BROKER_RIGHTS_BUY_HELP_LINK: 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431487415163&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank/DocumentoGS/DocumentoGSPreview',
    BROKER_RIGHTS_CASH_EXCHANGE_EFECTIVE_PERCENTAGE: 100,
    BROKER_RIGHTS_CASH_EXCHANGE_ERROR_RIGHTS_INPUT: 'rights_input',
    BROKER_RIGHTS_CASH_EXCHANGE_OPERATION_TYPE: "INE",
    BROKER_RIGHTS_CASH_EXCHANGE_PERCENTAGE_INDICATOR: "A",
    BROKER_RIGHTS_SELL_CORPORATE_OPERATION: 'V',
    BROKER_RIGHTS_SELL_FIELDS_MAXLENGHT_LIMIT: 999999999,
    BROKER_RISK_BUTTON_HIGH_RISK: 'high',
    BROKER_RISK_BUTTON_LOW_RISK: 'low',
    BROKER_RISK_BUTTON_MEDIUM_RISK: 'medium',
    BROKER_RISK_TYPE_SEVEN: 'risk-type-seven',
    BROKER_RISK_TYPE_SIX: 'risk-type-six',
    BROKER_ROBO_EDIT_FIELD_FONT: '22px Outfit Regular',
    BROKER_SEARCHER_SCREEN: 'SEARCHER',
    BROKER_SEARCH_RESULTS_MAX_CHARS_LABEL: 34,
    BROKER_SEARCH_RESULTS_TOTAL_RISK: 7,
    BROKER_SEARCH_RESULTS_TOTAL_STARS: 5,
    BROKER_SEARCH_VARIATION_DECIMALS: 3,
    BROKER_SEARCH_WIDGET_UTAG_CLOSE: 'cerrar',
    BROKER_SEARCH_WIDGET_UTAG_COLLAPSED_FILTERS: 'buscador/simple-colapsado',
    BROKER_SEARCH_WIDGET_UTAG_INPUT_NO_RESULTS: 'escritura sin resultados',
    BROKER_SEARCH_WIDGET_UTAG_INPUT_RESULTS: 'escritura con resultados',
    BROKER_SEARCH_WIDGET_UTAG_NAME: 'buscador/simple',
    BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH: 'ir a buscador/avanzado',
    BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH_OPEN_NO_RESULTS: 'buscador/simple-desplegado/sin resultados',
    BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH_OPEN_RESULTS: 'buscador/simple-desplegado/con resultados',
    BROKER_SEARCH_WIDGET_UTAG_OPEN: 'inicio',
    BROKER_SEARCH_WIDGET_UTAG_OPEN_PRODUCT: 'abrir ficha producto',
    BROKER_SEARCH_WIDGET_UTAG_VIEW_ALL_ETFS: 'ver todos los etfs',
    BROKER_SEARCH_WIDGET_UTAG_VIEW_ALL_FUNDS: 'ver todos los fondos',
    BROKER_SEARCH_WIDGET_UTAG_VIEW_ALL_PLANS: 'ver todos los planes',
    BROKER_SEARCH_WIDGET_UTAG_VIEW_ALL_RESULTS: 'ver todos los resultados',
    BROKER_SEARCH_WIDGET_UTAG_VIEW_ALL_STOCKS: 'ver todas las acciones',
    BROKER_SEARCH_WIDGET_UTAG_VIEW_ALL_WARRANTS: 'ver todos los warrans',
    BROKER_SELL_SCREEN: 'SELL',
    BROKER_SIMPLE_SEARCH_PAGE_NUM: '1',
    BROKER_SMALL_FLAG_SIZE: 16,
    BROKER_STOCKS_ADVANCED_BUY_AMOUNT_FIELDS_MAXLENGHT_LIMIT: 999999999,
    BROKER_STOCKS_ADVANCED_BUY_FIELDS_AMOUNT_MAXLENGHT_LIMIT: 999999999,
    BROKER_STOCKS_ADVANCED_BUY_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_STOCKS_ADVANCED_BUY_PERCENTAGE_FIELDS_MAXLENGHT_LIMIT: 3,
    BROKER_STOCKS_ADVANCED_SELL_AMOUNT_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_STOCKS_ADVANCED_SELL_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_STOCKS_ADVANCED_SELL_PERCENTAGE_FIELDS_MAX_VALUE: 100,
    BROKER_STOCKS_BUY_APPROX_IMPORT: 'aproxImport',
    BROKER_STOCKS_BUY_IMPORT_ID: 'IMPORTE',
    BROKER_STOCKS_BUY_NO_RESTRICTION: 'SIN RESTRICCION',
    BROKER_STOCKS_BUY_SELL_SUMMARY_BUY_MODIFIER: 'buy',
    BROKER_STOCKS_BUY_SELL_SUMMARY_SELL_MODIFIER: 'sell',
    BROKER_STOCKS_BUY_STEP1_BUY_FOR: 'buyFor',
    BROKER_STOCKS_BUY_STEP1_BUY_FOR_CONDITIONAL_INPUT: 'buyForConditionalInput',
    BROKER_STOCKS_BUY_STEP1_BUY_FOR_INPUT: 'buyForInput',
    BROKER_STOCKS_BUY_STEP1_LIMIT_DATE: 'limitDate',
    BROKER_STOCKS_BUY_STEP1_MAX_IMPORT_DIGITS: 9,
    BROKER_STOCKS_BUY_STEP1_MAX_TITLE_DIGITS: 8,
    BROKER_STOCKS_BUY_STEP1_ORDER_TYPE: 'orderType',
    BROKER_STOCKS_BUY_STEP1_ORDER_TYPE_LIMITED: 'orderTypeLimited',
    BROKER_STOCKS_BUY_STEP1_SELECTED_OPTION: 'stocksContractSelectedValue',
    BROKER_STOCKS_BUY_STEP1_SELECT_OPTIONS: 'selectOptions',
    BROKER_STOCKS_BUY_STEP1_VALIDITY_DATE: 'validityDate',
    BROKER_STOCKS_BUY_TITLE_AMOUNT_ID: 'CANT_TITULOS',
    BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_EXTERNAL_TRANSFER_MODIFIER: 'transferExternalValues',
    BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_MODIFIER: 'account',
    BROKER_STOCKS_CONTRACT_DROPDOWN_ACCOUNT_TRANSFER_VALUES_MODIFIER: 'accountTransferValues',
    BROKER_STOCKS_CONTRACT_DROPDOWN_ASSOCIATED_ACCOUNT_MODIFIER: 'associatedAccount',
    BROKER_STOCKS_CONTRACT_DROPDOWN_CONTRACTS_MODIFIER: 'contracts',
    BROKER_STOCKS_CONTRACT_DROPDOWN_DERIVATIVES_TRANSFER_MODIFIER: 'derivativesTransfer',
    BROKER_STOCKS_CONTRACT_DROPDOWN_FUNDS_TRANSFER_MODIFIER: 'fundsTransfer',
    BROKER_STOCKS_CONTRACT_DROPDOWN_FUTURES_DERIVATIVES_MODIFIER: 'futuresDerivates',
    BROKER_STOCKS_CONTRACT_DROPDOWN_GROUPED_CONTRACTS_MODIFIER: 'groupedContracts',
    BROKER_STOCKS_CONTRACT_DROPDOWN_FUND_SUBSCRIPTION_MODIFIER: 'fundsSubscription',
    BROKER_STOCKS_CONTRACT_DROPDOWN_TITLES_MODIFIER: 'titles',
    BROKER_STOCKS_CONTRACT_ORDER_TYPE: 'Contratacion',
    BROKER_STOCKS_CONTRACT_STANDARD: "1",
    BROKER_STOCKS_CONTRACT_SUBTYPE: "001",
    BROKER_STOCKS_CONTRACT_TYPE: "400",
    BROKER_STOCKS_CONTRACT_URL: '/myprofile/broker/stocksContract',
    BROKER_STOCKS_ETFS_INDEX_VARIATION_DECIMALS: 3,
    BROKER_STOCKS_HELP_URL: 'https://bancoonline.openbank.es/csopen/StaticBS?blobcol=urldata&blobheader=application%2Fpdf&blobkey=id&blobtable=MungoBlobs&blobwhere=1287277092992&cachecontrol=immediate&ssbinary=true&maxage=3600',
    BROKER_STOCKS_SALE_ORDER_SIGNATURE_PATTERN_PARAM: '00730100',
    BROKER_STOCKS_SELL_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_STOCK_ORDER_CONDITION_VALUE: 'stock',
    BROKER_SUBSCRIPTION_DISCRIMINADOR_CLAUSULA: 'N',
    BROKER_SUBSCRIPTION_SIGNATURE_PATTERN_OPERATION_TYPE: 38,
    BROKER_SUBSCRIPTION_SIGNATURE_PATTERN_PARAM: '00730100',
    BROKER_SUBSCRIPTION_TIPO_BOLSA: '1',
    BROKER_SUBSCRIPTION_TIPO_OPERACION: 'S',
    BROKER_SUBSCRIPTION_TITULOS: 0,
    BROKER_SYSTEM_NAME: 'BROKER',
    BROKER_TEMPORARY_SPC_INDEXES: 'OB_TICKER', // @hardcoded This constant must be removed
    BROKER_TICKER_SCREEN: 'TICKER',
    BROKER_TICKER_SPC_SYMBOL: 'OB_TICKER',
    BROKER_TODAYS_MAIN_STOCKS_DEFAULT_MARKET: 'IBEX 35',
    BROKER_TODAYS_MAIN_STOCKS_DEFAULT_TAB: 0,
    BROKER_TODAYS_MAIN_STOCKS_MAX_COLUMN_WIDTH: 390,
    BROKER_TODAYS_MAIN_STOCKS_MAX_COLUMN_WIDTH_PG_STOCKS_LANDING: 220,
    BROKER_TODAYS_MAIN_STOCKS_MAX_ROWS: 5,
    BROKER_TODAY_MAIN_STOCKS_SPC_SYMBOL: 'OB_Protagonistas_Hoy',
    BROKER_TRANSFER_CALLME_BACK_EXTERNAL_VALUES_URL: '/myprofile/broker/callmeBack/externalTransferValues',
    BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL: 'P',
    BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL_AMOUNT: 'AMOUNT',
    BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL_PARTICIPATION: 'PARTICIPATION',
    BROKER_TRANSFER_EXTERNAL_FUND_TOTAL: 'T',
    BROKER_TRANSFER_EXTERNAL_VALUES: '/myprofile/broker/externalTransferValues',
    BROKER_TRANSFER_FUNDS_ALL: 'ALL',
    BROKER_TRANSFER_PENSION_PLANS_DEST_EXIST_OPTION: 'E',
    BROKER_TRANSFER_PENSION_PLANS_DEST_NEW_OPTION: 'N',
    BROKER_TRANSFER_PENSION_PLAN_ACCOUNT_FORMAT: [4, 4, 2],
    BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND: 'ND',
    BROKER_TRANSFER_PENSION_PLAN_DESTINATION: 'D',
    BROKER_TRANSFER_PENSION_PLAN_ERROR_BELOW_MIN: 'BE',
    BROKER_TRANSFER_PENSION_PLAN_ERROR_OVER_MAX: 'OM',
    BROKER_TRANSFER_PENSION_PLAN_EUR: 'EUR',
    BROKER_TRANSFER_PENSION_PLAN_EXTERNAL_URL: '/myprofile/broker/pensions/transfer/external',
    BROKER_TRANSFER_PENSION_PLAN_FISC_A_OPTION: "1",
    BROKER_TRANSFER_PENSION_PLAN_FISC_A_OPTION_SERVICE: "ANTERIOR",
    BROKER_TRANSFER_PENSION_PLAN_FISC_B_OPTION: "2",
    BROKER_TRANSFER_PENSION_PLAN_FISC_B_OPTION_SERVICE: "POSTERIOR",
    BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION: "3",
    BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION_SERVICE: "AMBAS",
    BROKER_TRANSFER_PENSION_PLAN_FRACTION_LENGTH_AMOUNT_VALUE: 2,
    BROKER_TRANSFER_PENSION_PLAN_INTERNAL_TARGET_DESTINATION: 'D',
    BROKER_TRANSFER_PENSION_PLAN_INTERNAL_TARGET_ORIGIN: 'O',
    BROKER_TRANSFER_PENSION_PLAN_INTERNAL_URL: '/myprofile/broker/pensions/transfer/internal',
    BROKER_TRANSFER_PENSION_PLAN_MAXIMUN_INTEGER_AMOUNT_LENGTH: 6,
    BROKER_TRANSFER_PENSION_PLAN_ORIGIN: 'O',
    BROKER_TRANSFER_PENSION_PLAN_STEP_1: 0,
    BROKER_TRANSFER_PENSION_PLAN_STEP_2: 1,
    BROKER_TRANSFER_PENSION_PLAN_STEP_3: 2,
    BROKER_TRANSFER_PENSION_PLAN_STEP_4: 3,
    BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_OPTION: "2",
    BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_SERVICE: 'PARCIAL',
    BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_OPTION: "1",
    BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_SERVICE: 'TOTAL',
    BROKER_TRANSFER_PENSION_PLAN_URL: '/myprofile/broker/pensions/transfer/information',
    BROKER_TRANSFER_PENSION_REQUEST_DOCUMENT_URL: 'https://bancoonline.openbank.es/csopen/Satellite?c=DocumentoGS&canal=CWebComOB&cid=1431478225985&empr=BrokerOpenBank&leng=es_ES&pagename=BrokerOpenBank%2FDocumentoGS%2FDocumentoGSPreview',
    BROKER_TRANSFER_PLANS_BOLETIN_PREDATED_FAIL_MSG: '4',
    BROKER_TRANSFER_PLANS_GAP_FAIL_MSG: '3',
    BROKER_TRANSFER_PLANS_PREDATED_FAIL_MSG: '2',
    BROKER_TRANSFER_PLANS_STORAGE_FAIL_MSG: '5',
    BROKER_TRANSFER_VALUES_ERROR_MSG_V60736: 'TXT V6_0736',
    BROKER_TRANSFER_VALUES_ERROR_MSG_VV0005: 'TXT VV_0005',
    BROKER_TRANSFER_VALUES_ERROR_MSG_VV0013: 'TXT VV_0013',
    BROKER_TRANSFER_VALUES_ERROR_MSG_VV1196: 'TXT VV_1196',
    BROKER_TRANSFER_VALUES_ERROR_MSG_VV1197: 'TXT VV_1197',
    BROKER_TRANSFER_VALUES_ERROR_MSG_VV8300: 'TXT VV_8300',
    BROKER_TRANSFER_VALUES_TYPE_PARTIAL: 'partial',
    BROKER_TRANSFER_VALUES_TYPE_TOTAL: 'total',
    BROKER_TRASPASS_EXTERNAL_NUMERIC_VALIDATION_REGEX: /[^\d]+/g,
    BROKER_TRASPASS_EXTERNAL_VALUE_NUMBER: 1.0,
    BROKER_TRUNCATED_BREADCRUMB_ARROW_LENGTH: 3,
    BROKER_TRUNCATED_BREADCRUMB_MAX_SIZE: 96,
    BROKER_TYPE_AGENCIE: 'agencie',
    BROKER_TYPE_BANK: 'bOb',
    BROKER_TYPE_BANK_NAME: 'bankOrBox',
    BROKER_TYPE_ETFS_SUMMARY_DECIMALS: 3,
    BROKER_TYPE_RIGHT_NEW_DECIMALS: 4,
    BROKER_TYPE_STOCKS_NEW_DECIMALS: 4,
    BROKER_TYPE_STOCKS_NEW_DECIMALS_MIN_VALUE: 0.0001,
    BROKER_TYPE_STOCKS_NEW_DECIMALS_ROUNDER: 1000,
    BROKER_TYPE_STOCKS_OLD_DECIMALS_A: 3,
    BROKER_TYPE_STOCKS_OLD_DECIMALS_B: 2,
    BROKER_TYPE_WARRANTS_SUMMARY_DECIMALS: 2,
    BROKER_WALLET_TAB_TYPE: 'Type',
    BROKER_WALLET_TAB_VALUE: 'Value',
    BROKER_WARRANTS_BUY_BUYFOR_AMOUNT: 'amount',
    BROKER_WARRANTS_BUY_BUYFOR_TITLES: 'titles',
    BROKER_WARRANTS_BUY_BUYFOR_VALUE_DEFAULT: '',
    BROKER_WARRANTS_BUY_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_WARRANTS_BUY_LIMIT_PRICE_VALUE_DEFAULT: 1,
    BROKER_WARRANTS_CALL_TEXT: 'Call',
    BROKER_WARRANTS_CALL_VALUE: 'CALL',
    BROKER_WARRANTS_HELP_URL: 'https://bancoonline.openbank.es/csopen/StaticBS?blobcol=urldata&blobheader=application%2Fpdf&blobkey=id&blobtable=MungoBlobs&blobwhere=1287277106934&cachecontrol=immediate&ssbinary=true&maxage=3600',
    BROKER_WARRANTS_LEGAL_DOCUMENT_DFI: 'DFI',
    BROKER_WARRANTS_LEGAL_DOCUMENT_SEMIANUAL_REPORT: 'InformeSemestral',
    BROKER_WARRANTS_PUT_TEXT: 'Put',
    BROKER_WARRANTS_PUT_VALUE: 'PUT',
    BROKER_WARRANTS_RESULTS_ROWS_CALL_TYPE_ID: 1,
    BROKER_WARRANTS_RESULTS_ROWS_PUT_TYPE_ID: 2,
    BROKER_WARRANTS_SELL_FIELDS_MAXLENGHT_LIMIT: 9,
    BROKER_WARRANTS_SELL_LIMIT_PRICE_VALUE_DEFAULT: 1,
    BROKER_WARRANTS_SELL_TITLE_QUANTITY_VALUE_DEFAULT: '',
    BROKER_YELLOW_FUND: '3',
    BROKE_CASH_CHARGE_STEP1_SANTANDER_BANK_LABEL: 'www.bancosantander.es',
    BROKE_CASH_CHARGE_STEP1_SANTANDER_BANK_URL: 'https://www.bancosantander.es',
    BUTTON_TYPE: 3,
    BY_BUDGETS: 'budgets',
    BY_EXPENSES: 'expenses',
    BY_PORCENTAGE: 'Según porcentaje fijo',
    CALL_ME_BACK_REQUEST_HEADER_VERSION: 2,
    CALL_ME_BACK_REQUEST_HEADER_VERSION_THREE: 3,
    CALL_TIME_OPTIONS_RIGHT_AWAY_ES: 'enseguida',
    CALL_TIME_OPTIONS_STATUS_OK: 'OK',
    CALL_TIME_OPTIONS_WEEKEND_ES: 'sábados, domingos y feriados',
    CALL_TIME_OPTIONS_WEEKLY_ES: 'lunes a viernes',
    CAMPAIGN_PRICING_CHANNEL: 'PRIVATE',
    CARD_BLOCK_REASON_LOSS: 'loss',
    CARD_BLOCK_REASON_THEFT: 'theft',
    CARD_CHARGE_OPERATION_CHARGE: 'carga',
    CARD_CHARGE_OPERATION_DISCHARGE: 'descarga',
    CARD_CHARGE_OPERATION_REJECT: 'rechazar',
    CARD_CHARGE_SAVINGS_OPERATION_REJECT: 'traspaso-cancelar',
    CARD_CREDIT: 'credit', //leave as lowercase as its being used in translations
    CARD_DEBIT: 'debit', //leave as lowercase as its being used in translations
    CARD_DEFAULT_TRANSACTIONS_PAGE_NUMBER: 1,
    CARD_DEFERRABLE_PAYMENT: 'S',
    CARD_DEFERRED_PAYMENT: 'F',
    CARD_DEPOSIT_MONEY_PUESTO_FISICO: '00005', // fixed (confirmed by ale de la vina)
    CARD_DESIGN: 'cardDesign',
    CARD_ECARD: 'ecard', //leave as lowercase as its being used in translations
    CARD_EXPANDED_TRANSACTIONS_LIMIT: 5,
    CARD_INTERVENTION_ADITIONAL_MEMBER: 'MIEMBRO ADICIONAL',
    CARD_INTERVENTION_OWNER: 'TITULAR',
    CARD_MIN_BALANCE: 0.01,
    CARD_MODIFY_DEBIT_LIMITS_TYPE_COMMERCE: 'LC',
    CARD_MODIFY_DEBIT_LIMITS_TYPE_EXTRACT: 'LN',
    CARD_MODIFY_LIMIT_APPROVED_MESSAGE: 'CARD_MODIFY_LIMIT_APPROVED_MESSAGE',
    CARD_MODIFY_LIMIT_DECREASE: 'DECREASE',
    CARD_MODIFY_LIMIT_INCREASE_APPROVED: 'APPROVED',
    CARD_MODIFY_LIMIT_INCREASE_PENDING: 'PENDING',
    CARD_MODIFY_LIMIT_MAX_TO_AUTHORIZE: 3000,
    CARD_MODIFY_LIMIT_OPERATION_ERROR_MESSAGE: 'CARD_MODIFY_LIMIT_OPERATION_ERROR_MESSAGE',
    CARD_MODIFY_LIMIT_PENDING_MESSAGE: 'CARD_MODIFY_LIMIT_PENDING_MESSAGE',
    CARD_MODIFY_LIMIT_REJECTED_MESSAGE: 'CARD_MODIFY_LIMIT_REJECTED_MESSAGE',
    CARD_MODIFY_LIMIT_SERVICE_ERROR_MESSAGE: 'CARD_MODIFY_LIMIT_SERVICE_ERROR_MESSAGE',
    CARD_MOVEMENTS_FUNDABLE: 'fundable', //leave as lowercase as its being used in translations
    CARD_MOVEMENTS_FUNDED: 'funded', //leave as lowercase as its being used in translations
    CARD_MOVEMENTS_LIQUIDATED: 'liquidado', //leave as lowercase as its being used in translations
    CARD_MOVEMENTS_PENDING_AUTHORIZED: 'autorizado',
    CARD_MOVEMENTS_PENDING_LIQUIDATION: 'pdte.liquidar', //leave as lowercase as its being used in translations
    CARD_NOT_DEFERRABLE_PAYMENT: 'N',
    CARD_OPENYOUNG: 'openyoung',
    CARD_OPEN_NOM: '1,39%',
    CARD_OPEN_TAE: '18,00%',
    CARD_OPEN_TIN: '16,67%',
    CARD_PAYMENT_METHOD_FIXED: 'CF',
    CARD_PAYMENT_METHOD_UNIQUE: 'PM',
    CARD_PAYMENT_METHOD_VARIABLE: 'PA',
    CARD_PAYMENT_METHOD_VARIABLE_INCREMENT_INTERVAL: 5,
    CARD_PAYMENT_METHOD_VARIABLE_MAX_PERCENTAGE: 90,
    CARD_PAYMENT_METHOD_VARIABLE_MIN_PERCENTAGE: 5,
    CARD_PLACEHOLDER: 'XXXX',
    CARD_PREMIUM_OR_DIAMOND_NOM: '0,80%',
    CARD_PREMIUM_OR_DIAMOND_TAE: '10,00%',
    CARD_PREMIUM_OR_DIAMOND_TIN: '9,57%',
    CARD_REVOLVING_PRODUCT_STANDARD: '0000001',
    CARD_REVOLVING_PRODUCT_SUBTYPE: '003',
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK1: '804',
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK1_ID: 1,
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK2: '805',
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK2_ID: 2,
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK3: '806',
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK3_ID: 3,
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK4: '807',
    CARD_REVOLVING_PRODUCT_SUBTYPE_PACK4_ID: 4,
    CARD_REVOLVING_PRODUCT_TYPE: '500',
    CARD_SITUATION_FILTER_ALL: 'TODOS',
    CARD_SITUATION_FILTER_AUTHORIZED: 'AUTORIZADO',
    CARD_SITUATION_FILTER_LIQUIDATED: 'LIQUIDADO',
    CARD_SITUATION_FILTER_PENDING_LIQUIDATION: 'PDTE.LIQUIDAR',
    CARD_SIZE_BIG: 'big',
    CARD_SIZE_MEDIUM: 'medium',
    CARD_STATEMENT_MONTHLY_EXTRACT: 'CARD_STATEMENT_MONTHLY_EXTRACT',
    CARD_STATEMENT_SPECIAL_PURCHASE: 'CARD_STATEMENT_SPECIAL_PURCHASE',
    CARD_STATE_FILTER_ALL: 'T',
    CARD_STATE_FILTER_DEFERRED: 'F',
    CARD_STATE_FILTER_NOT_DEFERRED: 'S',
    CARD_TRANSFER_COMMISSION_PERCENT: 3.9,
    CARD_TRANSFER_MINIMUM_AMOUNT: 100,
    CARD_TYPE: 'cardType',
    CARD_TYPE_AMEX: 'AMEX',
    CARD_TYPE_MASTER: 'MASTER CARD',
    CARD_TYPE_VISA_OPEN: 'VISA OPEN',
    CARD_TYPE_VISA_ORO: 'VISA ORO',
    CARD_VIRTUAL_CREDIT: 'Virtual Only Credit',
    CARD_VIRTUAL_DEBIT: 'Virtual Only Debit',
    CATEGORY_TYPE_ACCOUNT: 0,
    CATEGORY_TYPE_CARD: 1,
    CC: 'CC',
    CCO_ALREADY_UPDATED: '4100126',
    CCV_ACCOUNT: 'CCV_ACCOUNT',
    OCU_CC_PRODUCTION_DOMAIN: 'ocu.cc.openbank.es',
    PROD_CC_PRODUCTION_DOMAIN: 'prod.cc.openbank.es',
    CHARGE_ACCOUNT_PREFIX: '00730100',
    CHARITYURL: 'charity',
    CHARITY_CARD_DEFAULT: 'B.506.011.001',
    CHARITY_SUB_TYPES: ['011', '013', '014', '015', '019', '020', '021', '022'],
    CHARITY_TYPE: '506',
    CHECKING_ACCOUNT: 'checking-account',
    CHECKING_ACCOUNT_CODE: 300,
    CHECKING_ACCOUNT_STANDARD: '0000001',
    CHECKING_ACCOUNT_SUBTYPE: '800',
    CHECKING_ACCOUNT_TYPE: '300',
    CHEQUEBOOK_FILTER_COMPOSED: 'composed', //leave as lowercase as its being used in translations
    CHEQUEBOOK_FILTER_IRREGULAR: 'irregular', //leave as lowercase as its being used in translations
    CHEQUEBOOK_FILTER_PAYED: 'payed', //leave as lowercase as its being used in translations
    CHEQUEBOOK_FILTER_PAYED_RETURNED_COMPOSED: 'payedReturnedComposed', //leave as lowercase as its being used in translations
    CHEQUEBOOK_FILTER_PENDING: 'pending', //leave as lowercase as its being used in translations
    CHEQUEBOOK_FILTER_RETURNED: 'returned', //leave as lowercase as its being used in translations
    CHORE_MAX_AMOUNT: 1000,
    CHORE_MIN_AMOUNT: 0.01,
    CIC_CHANNEL: 'CIC',
    CIRCULAR_PROGRESS_BAR_FINAL_PERCENT: 100,
    CIRCULAR_PROGRESS_BAR_INITIAL_PERCENT: 0,
    CIRCULAR_PROGRESS_BAR_STROKE_COLOR: '#FF0049',
    CIRCULAR_PROGRESS_BAR_STROKE_WIDTH: 8,
    CLIENT_ERROR: 'Client Error',
    CMS_PRIVATE_PREFIX: 'https://homebanking.openbank',
    CMS_PUBLIC_PREFIX: 'https://public.openbank',
    CNMV_URL: 'https://www.cnmv.es',
    CODBBAN_LENGTH: 20,
    CODE_ADDRESS_EXT: 'EXT',
    CODIGO_FAMILIAR_PRODUCTOS: 'CCO',
    COLLECTIVE_ETF_BUY: 'Contratacion_Buy_ETF',
    COLLECTIVE_ETF_COMMISSION: 'Contratacion_Landing_ETF',
    COLLECTIVE_ETF_SELL: 'Contratacion_Sell_ETF',
    COLLECTIVE_ID: 'COLLECTIVE_ID',
    COLLECTIVE_PRODUCT_LIST_URL_PARAM: 'product_cart',
    COLLECTIVE_STOCK_COMMISSION: 'Contratacion_Landing_Stocks',
    COLLECTIVOS_NTIFCTN_CRRNT_TO_PAYROLL: 'COLLECTIVOS_NTIFCTN_CRRNT_TO_PAYROLL',
    COLLECTIVOS_NTIFCTN_HAS_NO_PAYROLL_AND_CURRENT_ACNT: 'COLLECTIVOS_NTIFCTN_HAS_NO_PAYROLL_AND_CURRENT_ACNT',
    COLLECTIVOS_NTIFCTN_HAS_PAYROLL_ACCNT: 'COLLECTIVOS_NTIFCTN_HAS_PAYROLL_ACCNT',
    COLLECTIVOS_STOCKS_ADVANCE_BUY_LOCATION: 'Contratacion_AdvanceBuy_Stocks',
    COLLECTIVOS_STOCKS_ADVANCE_SELL_LOCATION: 'Contratacion_AdvanceSell_Stocks',
    COLLECTIVOS_STOCKS_BUY_LOCATION: 'Contratacion_Buy_Stocks',
    COLLECTIVOS_STOCKS_SELL_LOCATION: 'Contratacion_Sell_Stocks',
    COLON: ':',
    COMBINED_DEPOSIT: '844',
    COMMERCIAL_ADS_CHANNEL: 'private',
    COMMERCIAL_ADS_CHANNEL_ES: 'privada',
    COMMERCIAL_ADS_EMPTY_BANNER_URI: 'https://www.openbank.es/es/',
    COMMERCIAL_ADS_EMPTY_WAFER_URI: '/myprofile/cards',
    COMMERCIAL_INTERSTITIAL_HOLE: 'B.1.1',
    COMPARATIVE_CIRCLE_LARGE_ONE_ARC: '1',
    COMPARATIVE_CIRCLE_LARGE_ZERO_ARC: '0',
    COMPOSITE_TYPE: 7,
    CONNECTION_CHANNEL_CONTACT_CENTER: 'contact center',
    CONNECTION_CHANNEL_INTERNET: 'internet',
    CONNECTION_CHANNEL_INTRANET: 'intranet',
    CONNECTION_ERROR: 'Connection Error',
    CONTENT_TYPE_PDF: 'application/pdf',
    CONTRACTS_ACTIVE_TAB: 1,
    CONTRACT_INDEX_COMBINED_DEPOSIT: 'combined-deposit',
    CONTRACT_INDEX_DEPOSIT_18_MONTHS_RETENTION: 'deposit-eighteen-months-retention',
    CONTRACT_INDEX_DEPOSIT_24_MONTHS_RETENTION: 'deposit-twentyfour-months-retention',
    CONTRACT_INDEX_DEPOSIT_NEW_MONEY: 'depositnm',
    CONTRACT_INDEX_DEPOSIT_WITHOUT_NEW_MONEY: 'deposit',
    CONTRACT_INDEX_DEPOSIT_TRANSFER: 'deposittransfer',
    CONTRACT_INDEX_DEPOSIT_RENEWAL: 'depositrenewal',
    CONTRACT_INDEX_DEPOSIT_EXPIRATION: 'depositexpired',
    CONTRACT_INDEX_PAYROLL_ACCOUNT: 'payroll-account',
    CONTRACT_INDEX_UPGRADE_TO_PAYROLL_ACCOUNT: 'upgrade-to-payroll-account',
    CONTRACT_SUMMARY_CONTACT_FAX_NO_PREFIX: '91\u00a0183\u00a010\u00a015', // 91 183 10 15          (91 is Madrid prefix, in this case after 91 should be grouped in 3-2-2)
    CONTRATO_PRESTACION_SERVICIOS_PERSONAS_FISICAS: 'https://www.openbank.es/assets/static/pdf/Pie%20de%20P%C3%A1gina/Contrato%20de%20prestacion%20de%20servicios%20de%20personas%20fisicas.pdf',
    CONTRATO_PRESTACION_SERVICIOS_PERSONAS_FISICAS_FILE_EXTENSION: 'documentosCuentadeValores.pdf',
    CREDIT_CARD_ID_RESPONSE_APPROVED: [3, 4, 5, 6],
    CREDIT_CARD_ID_RESPONSE_PENDING: [0, 1, 2, 10],
    CREDIT_CARD_ID_RESPONSE_REJECTED: [7, 9],
    CREDIT_CARD_STANDARD: '0000012',
    CREDIT_CARD_STANDARD_VIA_T: '0000001',
    CREDIT_CARD_SUBTYPE_DIAMOND: '002',
    CREDIT_CARD_SUBTYPE_PREMIUM: '003',
    CREDIT_CARD_SUBTYPE_VIA_T: '001',
    CREDIT_CARD_SUBTYPE_VISA: '801',
    CREDIT_CARD_STANDAR_VISA: '001',
    CREDIT_CARD_PROCESSTYPE_VISA: 'precontractual',
    CREDIT_CARD_TYPE_PACK: '501',
    CREDIT_CARD_TYPE_VIA_T: '532',
    CREDIT_CARD_TYPE_VISA: '500',
    CREDIT_CARD_STANDAR_TYPE: '500',
    CUENTA_CARGO_CANAL: 'CIC',
    CUENTA_AJENA_MAYOR_OFFICIAL: 'CUENTA_AJENA_MAYOR_OFFICIAL',
    CUENTA_AJENA_MAYOR_5: 'CUENTA_AJENA_MAYOR_5',
    CURRENT_ACCOUNT_TYPE: "300",
    DATETIME_FORMAT_ES: 'DD MMM YYYY h:mm',
    DATETIME_FORMAT_WITH_SLASHES: 'DATETIME_FORMAT_WITH_SLASHES',
    DATE_FILTER_ANNUAL: 'Annual',
    DATE_FILTER_BIANNUAL: 'Biannual',
    DATE_FILTER_CUSTOM: 'Custom',
    DATE_FILTER_FORTNIGHTLY: 'Fortnightly',
    DATE_FILTER_MONTHLY: 'Monthly',
    DATE_FILTER_NONE: 'None',
    DATE_FILTER_THREE_MONTHS: 'ThreeMonths',
    DATE_FILTER_TWO_YEARS: 'TwoYears',
    DATE_FORMAT: 'DATE_FORMAT',
    DATE_FORMAT_API2: 'DD/MM/YYYY HH:mm:ss',
    DATE_FORMAT_API: 'YYYY-MM-DD',
    DATE_FORMAT_API_DAY_MONTH_YEAR: 'DD-MM-YYYY',
    DATE_FORMAT_API_MONTH_DAY_YEAR: 'MM-DD-YYYY',
    DATE_FORMAT_COMPLETE: 'DD MMM YYYY - HH:mm',
    DATE_FORMAT_DATETIME: 'DATE_FORMAT_DATETIME',
    DATE_FORMAT_DAY_MONTH: 'DATE_FORMAT_DAY_MONTH',
    DATE_FORMAT_DAY_MONTH_SHORT: 'DD MMM',
    DATE_FORMAT_DAY_MONTH_YEAR: 'DD MMM YYYY',
    DATE_FORMAT_DAY_NUMBER: 'DD',
    DATE_FORMAT_DAY_TEXT_LONG: 'dddd',
    DATE_FORMAT_DAY_TEXT_SHORT: 'ddd',
    DATE_FORMAT_EN: 'MMM DD YYYY',
    DATE_FORMAT_ES: 'DD MMM YYYY',
    DATE_FORMAT_EXTENDED: 'DATE_FORMAT_EXTENDED',
    DATE_FORMAT_FULL_MONTH: 'MMMM',
    DATE_FORMAT_FULL_MONTH_YEAR: 'MMMM YYYY',
    DATE_FORMAT_MONTH_YEAR: 'MMM YYYY',
    DATE_FORMAT_SHORT_MONTH_SHORT_YEAR: 'DATE_FORMAT_SHORT_MONTH_SHORT_YEAR',
    DATE_FORMAT_SHORT_TIME_24: 'HH:mm',
    DATE_FORMAT_SLASHED_ES: 'DD/MM/YYYY HH:mm',
    DATE_FORMAT_SLASHES_EN: 'MM/DD/YYYY',
    DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR: 'DD/MM/YYYY',
    DATE_FORMAT_SLASHES_SHORT_MONTH_YEAR: 'MM/YYYY',
    DATE_FORMAT_TIME: 'h:mm',
    DATE_FORMAT_WITH_DASHES: 'DATE_FORMAT_WITH_DASHES',
    DATE_FORMAT_WITH_SLASHES: 'DATE_FORMAT_WITH_SLASHES',
    DATE_FOR_INFINITE: '9999-12-31',
    DATE_FORMAT_SHORT_MONTH_YEAR: 'DATE_FORMAT_SHORT_MONTH_YEAR',
    DEBITS_RETURN_CAUSE_TYPE: 'DEV',
    DEBITS_RETURN_PERSONAL_4: '1021',
    DEBIT_AT_HOME_ABROAD_STANDARD: '0000001',
    DEBIT_AT_HOME_ABROAD_SUBTYPE: '005',
    DEBIT_CARD_SUBTYPE_DIAMOND: '008',
    DEBIT_CARD_SUBTYPE_PREMIUM: '009',
    DEBIT_CARD_TYPE: '506',
    DECLARATION_RESULT_TO_GIVE_BACK: 'TO_GIVE_BACK',
    DECLARATION_RESULT_TO_PAY: 'TO_PAY',
    DEFAULT_AXIS_ROTATION: 0,
    DEFAULT_BALANCE: 0,
    DEFAULT_COMMISSION: '13,99',
    DEFAULT_COUNTRY: 'ES',
    DEFAULT_DOCUMENT_TYPE: 'TIN',
    DEFAULT_LIMIT: '50',
    DEFAULT_NOTIFICATION_LIST_FROM: 1,
    DEFAULT_NOTIFICATION_LIST_FROM_UNIT: 'year',
    DEFAULT_SWEEP_FLAG: 1,
    DEFAULT_TIMEOUT: 60000, // /v2/posicion-global-total times out if it's lower
    DEFERRED_PAYMENT_INSUFFICIENT_BALANCE_ERROR_CODE: 'M4_2844',
    DEFERRED_PAYMENT_LIMIT_SUM: 'M4_1504',
    DEFERRED_PAYMENT_SUM: 'M4_1545',
    DEFERRED_TRANFERS: 'TRANSFERENCIAS DIFERIDAS',
    DELETE_KEYCODE: 46,
    DEPOSIT_13_MONTHS_CUSTOM_ERROR_NOT_ALLOWED: { type: 'custom', errorObject: 'notAllowed' },
    DEPOSIT_13_MONTHS_EXCEPCIONES_PRODUCT_STANDARD: '0000006',
    DEPOSIT_13_MONTHS_EXCEPCIONES_PRODUCT_SUBTYPE: '868',
    DEPOSIT_13_MONTHS_EXCEPCIONES_PRODUCT_TYPE: '302',
    DEPOSIT_13_MONTHS_MAX_AMOUNT_CODE_ERROR: 'IY1112',
    DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_STANDARD: '0000007',
    DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_SUBTYPE: '868',
    DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_TYPE: '302',
    DEPOSIT_13_MONTHS_ZERO_AMOUNT_CODE_ERROR: 'IY1113',
    DEPOSIT_CLOSED_URL: '/deposits/closed',
    DEPOSIT_CLOSED_FULL_URL: '/myprofile/deposits/closed',
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN: '0001',
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL: 'KFC',
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE: '11111',
    DEPOSIT_HIRE_CONFIRMATION_MANAGER: '000213',
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_STANDARD: '0000004',
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE: '895', // 868 for PRE
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE_CC: '895',
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE_FOR_GET_HIRE_DATA: '895', // 879 for PRE
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE_FOR_GET_HIRE_DATA_CC: '895',
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_TYPE: '302',
    DEPOSIT_INTERVENTION_OWNER: 'TITULAR',
    DEPOSIT_EIGHTEEN_MONTHS: 'deposit-eighteen-months',
    DEPOSIT_CAN_RENEW: 'CAN_RENEW',
    DEPOSIT_RENEWAL_SCHEDULED: 'RENEWAL_SCHEDULED',
    DEPOSIT_RENEWAL_OPERATIVE_NAME: 'operatives-operatives_depositRenewal',
    DEPOSIT_THIRTEEN_MONTHS: 'deposit-thirteen-months',
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS: 'deposit-thirteen-months-excepciones',
    DEPOSIT_THIRTEEN_MONTHS_RENEWAL: 'deposit-thirteen-months-renovacion-excepciones',
    DESCRIPTION_FIELD_REGEX: /^[\sa-zA-Z0-9(;:)]*$/,
    DEVICES_TO_SHOW: 10,
    DEVICE_TYPE_EMAIL: 'I',
    DEVICE_TYPE_PHONE: 'T',
    DEV_ENVIRONMENT: 'DEV',
    DE_MOBILE_VALIDATION_REGEX: /^[+\d]{0,12}$/,
    DE_PHONE_NUMBER_MIN_LENGTH: 7,
    DE_POSTAL_CODE_MAX_LENGTH: 5,
    DIAMOND_CARD: 'DIAMOND_CARD',
    DIAMOND_PACK_HIRING: 'diamond_pack_hiring',
    DIAMOND_PACK_STANDARD: '0000003',
    DISPLAY_WATCHLIST_TOOLTIP_BY_DEFAULT: 'watchlistTooltip_displayBydefault',
    DOCUMENT_NUMBER_NIE_REGEX: /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
    DOCUMENT_NUMBER_NIF_INVALID_CHARS: /[^a-z0-9]+/i,
    DOCUMENT_NUMBER_NIF_PASSPORT_MAX_CHARS: 11,
    DOCUMENT_NUMBER_NIF_PASSPORT_REGEX2: /^[a-z0-9]{8,11}$/i,
    DOCUMENT_NUMBER_NIF_PASSPORT_REGEX: /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
    DOCUMENT_TYPE_NIE: 'C',
    DOCUMENT_TYPE_NIE_CITIZEN_REGISTRATION: 'CR',
    DOCUMENT_TYPE_NIE_RESIDENT_CARD: 'RC',
    DOCUMENT_TYPE_NIF: 'N',
    DOCUMENT_TYPE_NIF_LENGTH: 9,
    DOCUMENT_TYPE_PASSPORT: 'P',
    DOWNLOAD_LINK_BUTTON_TYPE: 'downloadLinkButtonType',
    DOCUMENTATION_URL: '/myprofile/documentation',
    DOCUMENTATION_UPLOAD_PROCESS_URL: '/myProfile/documentation/uploadDocumentation',
    DOCUMENTATION_UPLOAD_PROCESS_SUCCESS_URL: '/myProfile/documentation/uploadDocumentation/success',
    DOCUMENTATION_UPLOAD_PROCESS_ERROR_URL: '/myProfile/documentation/uploadDocumentation/error',
    DOCUMENT_PRINT_WINDOWS_PARAMS: 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,resizable,screenX=50,screenY=50,width=850,height=1050',
    ECARD_MAX_BALANCE: 3000,
    ECARD_STANDARD: '0000001',
    ECARD_SUBTYPE: '003',
    ECARD_TYPE: '506',
    ECONOMIC_CODE_DEFAULT: '005',
    EDIT_ALIAS_ICON_WIDTH: 26,
    EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    EMPTY_CONCEPT: '-',
    EMPTY_REDIRECT_URL: 'javascript:;',
    EMPTY_REGEX: /^$/,
    EMPTY_STATE_NOCARD_BANKING: 'nocard-banking',
    ENGLISH_LANGUAGE: 'en',
    ENTER_KEY_CODE: 13,
    ENTITY_CODE_ING: '1465',
    ENTITY_CODE_ING_API: '0083',
    ENTITY_CODE_SANTANDER: '0049',
    ENTITY_CODE_SANTANDER_API: '2898',
    ERROR_RIGHTS: 'ERROR',
    ES_POSTAL_CODE_MAX_LENGTH: 5,
    ES_PREPOSITION: '[de]',
    ETFS_BUY: 'ETF_BUY',
    ETFS_SELL: 'ETFS_SELL',
    EURONEXT_EURONEXT_AMSTERDAM: 'EURONEXT - EURONEXT AMSTERDAM',
    EURONEXT_EURONEXT_BRUSSELS: 'EURONEXT - EURONEXT BRUSSELS',
    EURONEXT_EURONEXT_BRUSSELS_DERIVATIVES: 'EURONEXT - EURONEXT BRUSSELS - DERIVATIVES',
    EURONEXT_EURONEXT_LISBON: 'EURONEXT - EURONEXT LISBON',
    EURONEXT_EURONEXT_PARIS: 'EURONEXT - EURONEXT PARIS',
    EURO_SYMBOL: '€',
    EURO_TEXT: 'EUR',
    EXANT_COST_ASSOCIATED_TO_SERVICE: 'associatedToService',
    EXANT_COST_SUCCESS_COMMISSION: 'successCommission',
    EXPIRATION_CODE_TIME: 10000,
    EXPIRATION_CODE_TIME_CHALLENGE: 10000,
    EXPIRED_RIGHTS: 'EXPIRED',
    EXTERNAL_TRANSFER_VALUES_GDPR_LINK: 'https://www.openbank.es/assets/static/pdf/PieDePagina/ClausulaGDPR.pdf',
    EXTRACTS_VIEW_MAX_DOCS_PER_VIEW: 15,
    FATAL: 3,
    FIFTH_STEP: 4,
    SIXTH_STEP: 5,
    FIJO_PREFIX: '034',
    FILTERS_CANCELED: 'CA',
    FILTERS_INVALID: 'N',
    FILTERS_VALID: 'S',
    FIOC_DOCUMENT_CODE_01: "01",
    FIOC_DOCUMENT_CODE_02: "02",
    FIOC_DOCUMENT_CODE_03: "03",
    FIOC_DOCUMENT_CODE_10: "10",
    FIOC_DOCUMENT_CODE_20: "20",
    FIRST_STEP: 0,
    FISCAL_INFORMATION_GROUP: '0033',
    FIVE_HUNDRED_TWELVE: 512,
    FORMATTEDTEXT_TYPE: 'formattedtext_type',
    FORM_VALIDATION_HELPER_SHOW_ERROR: true,
    FOR_THE_BEST_CODE: 0,
    FOR_THE_BEST_ORDER_CLASS: 0,
    FOURTH_STEP: 3,
    FULL_PLANS_UNIVERSE_ID: 'FOESP$$PEN',
    FUNDS: 'funds', //leave as lowercase as its being used in translations //   = (santiago) it should not
    FUNDS_ABLE_TO_BE_HIRED_FILTER: 'CustomCategoryId3:IN:1:2:3',
    FUNDS_MAX_INPUT_VALUE: 9999999.99,
    GENDER_FEMALE: 'H',
    GENDER_MALE: 'V',
    GEOZONE_REGION_CODE_AFRICA: 8,
    GEOZONE_REGION_CODE_ASIA_DEVELOPED: 12,
    GEOZONE_REGION_CODE_ASIA_EMERGING: 13,
    GEOZONE_REGION_CODE_AUSTRALASIA: 11,
    GEOZONE_REGION_CODE_CANADA: 2,
    GEOZONE_REGION_CODE_DEVELOPED_COUNTRY: 15,
    GEOZONE_REGION_CODE_EMERGING_MARKET: 14,
    GEOZONE_REGION_CODE_EUROPE_EMERGING: 7,
    GEOZONE_REGION_CODE_EUROPE_EX_EURO: 6,
    GEOZONE_REGION_CODE_EUROZONE: 5,
    GEOZONE_REGION_CODE_JAPAN: 10,
    GEOZONE_REGION_CODE_LA: 3,
    GEOZONE_REGION_CODE_MIDDLE_EAST: 9,
    GEOZONE_REGION_CODE_NOT_CLASSIFIED: 16,
    GEOZONE_REGION_CODE_UK: 4,
    GEOZONE_REGION_CODE_US: 1,
    GP_EXPENSES_FILTER_COMPARE_NONE: 'NONE',
    GP_EXPENSES_FILTER_COMPARE_PREVIOUS: 'PREVIOUS',
    GP_EXPENSES_FILTER_COMPARE_PROYECTED: 'PROYECTED',
    GP_EXPENSES_FILTER_CURRENCY_DOLAR: 'USD',
    GP_EXPENSES_FILTER_CURRENCY_EURO: 'EUR',
    GP_EXPENSES_FILTER_CURRENCY_FRANCO: 'CHF',
    GP_EXPENSES_FILTER_CURRENCY_LIBRA: 'GBP',
    GP_EXPENSES_FILTER_TYPE_ACCOUNTS: 'ACCOUNTS',
    GP_EXPENSES_FILTER_TYPE_ACCOUNT_ALL: 'all',
    GP_EXPENSES_FILTER_TYPE_ACCOUNT_CUSTOM: 'custom',
    GP_EXPENSES_FILTER_TYPE_ALL: 'ALL',
    GP_EXPENSES_FILTER_TYPE_CARDS: 'CARDS',
    GP_REQUEST_HEADER_VERSION: 5,
    GRAPHIC_TUTOR_TYPE_10: 10,
    GRAPHIC_TUTOR_TYPE_11: 11,
    GRAPHIC_TUTOR_TYPE_12: 12,
    GRAPHIC_TUTOR_TYPE_13: 13,
    GRAPHIC_TUTOR_TYPE_14: 14,
    GRAPHIC_TUTOR_TYPE_15: 15,
    GRAPHIC_TUTOR_TYPE_1: 1,
    GRAPHIC_TUTOR_TYPE_2: 2,
    GRAPHIC_TUTOR_TYPE_3: 3,
    GRAPHIC_TUTOR_TYPE_4: 4,
    GRAPHIC_TUTOR_TYPE_5: 5,
    GRAPHIC_TUTOR_TYPE_6: 6,
    GRAPHIC_TUTOR_TYPE_7: 7,
    GRAPHIC_TUTOR_TYPE_8: 8,
    GRAPHIC_TUTOR_TYPE_9: 9,
    GRAPHIC_TUTOR_TYPE_BROKER_CW: 'tg-tutorial-7',
    GRAPHIC_TUTOR_TYPE_GP: 'tg-gpv',
    HIDE_BANNER_BUTTON_LINK_CC: 'hideBannerLinkCC',
    HIRE_E_ACCOUNT_MANAGER: '000213',
    HIRE_IMMEDIATE_CREDIT_ERRORS: [
        'GD0216#PETICION PENDIENTE DE ESTUDIAR. NO ALCANZA NOMINAL.',
        'GD0219#PETICION PENDIENTE ESTUDIO DPTO RIESGO. SOLICITUD SUPERA MAXIMO',
        'GD0220#LIMITE MEDIO DE PAGO OCUPA RIESGO TOTAL',
        'LIMITE MEDIO DE PAGO OCUPA RIESGO TOTAL',
        'PETICION PENDIENTE DE ESTUDIAR. NO ALCANZA NOMINAL.',
        'PETICION PENDIENTE ESTUDIO DPTO RIESGO. SOLICITUD SUPERA MAXIMO',
    ],
    HIRE_VALUES_CONTRACT_URL: '/myprofile/broker/stocksContract',
    HYPHEN: '-',
    IBAN_ES_REGEX: /^ES\d{22}$/,
    IBAN_LENGTH: 24,
    IBAN_LENGTH_WITH_SPACES: 29,
    IBAN_PLACEHOLDER: '0000 0000 0000 0000 0000',
    IBAN_PLACEHOLDER_2: 'ES-- ---- ---- ---- ---- ----',
    IBAN_REGEX: /^[A-Z]{2}\d{22}$/i,
    IBAN_SHORT_LENGTH: 4,
    IBEX35_ISIN: 'ES0SI0000005',
    IBEX35_MARKET_CODE: 43,
    IBEX35_SPC_SYMBOL: 'I:IB',
    IBEX35_TEXT: 'IBEX 35',
    IMMEDIATE_FREE: 'IMMEDIATE_FREE',
    IMMEDIATE_NOT_FREE: 'IMMEDIATE_NOT_FREE',
    INCREASE_AMOUNT_CARD_MOBILE_RECHARGE: 5,
    INMEDIATA: 'INMEDIATA',
    INTERVENER_AUTHORIZED: 'intervenerType-authorized',
    INTERVENER_COTITULAR: 'intervenerType-cotitular',
    INTERVENER_LABEL_ADMINISTRATOR: 'administrator',
    INTERVENER_LABEL_AUTHORIZED: 'authorized',
    INTERVENER_LABEL_EMPOWERED: 'empowered',
    INTERVENER_LABEL_HOLDER: 'holder',
    INTERVENER_LABEL_LEGAL_REPRESENTATIVE: 'legalRepresentative',
    INTERVENER_LEGAL_REPRESENTATIVE: 'intervenerType-legalRepresentative',
    INTERVENER_TITULAR: 'intervenerType-titular',
    INTERVENER_TYPE: 'intervenerType',
    INTERVENER_TYPE_PROPIETARIO: 'PROPIETARIO',
    INTERVENER_TYPE_TITULAR: 'TITULAR',
    IN_PROGRESS_RIGHTS: 'IN_PROGRESS',
    JUNIOR_ACCOUNT_ACCOUNT_TYPE: 'C',
    JUNIOR_ACCOUNT_ACTIVITY_CODE: '000',
    JUNIOR_ACCOUNT_ANNUAL_INCOME_AMOUNT: 1,
    JUNIOR_ACCOUNT_CAMPANA: '0001',
    JUNIOR_ACCOUNT_CANAL_COMERCIALIZACION: 'KFC',
    JUNIOR_ACCOUNT_CIVIL_STATUS: 'N',
    JUNIOR_ACCOUNT_CNAE93: '00999',
    JUNIOR_ACCOUNT_CNO: 'X300',
    JUNIOR_ACCOUNT_COD_ESTANDAR_REFERENCIA: '0000001',
    JUNIOR_ACCOUNT_DEVICE1_ORIGIN: '04',
    JUNIOR_ACCOUNT_DEVICE_TYPE: 'T',
    JUNIOR_ACCOUNT_FORMATO_DOMIC: '11',
    JUNIOR_ACCOUNT_GESTOR: '000213',
    JUNIOR_ACCOUNT_HIRING_INITIATION_POINT: 'SAVING_MONEY_FLOW',
    JUNIOR_ACCOUNT_ID_SUB_PRODUCT: '101',
    JUNIOR_ACCOUNT_IND_EMPLOYEE: 'N',
    JUNIOR_ACCOUNT_IND_FISCAL_NO: 'N',
    JUNIOR_ACCOUNT_IND_FISCAL_YES: 'S',
    JUNIOR_ACCOUNT_IND_PERS_TRABAJA: 'O',
    JUNIOR_ACCOUNT_IND_TALONARIO: 'N',
    JUNIOR_ACCOUNT_INTERVENTION_TYPE: '00',
    JUNIOR_ACCOUNT_LANGUAGE: 'E',
    JUNIOR_ACCOUNT_LEVEL_OF_STUDIES: '05',
    JUNIOR_ACCOUNT_PRODUCT_ID: '301',
    JUNIOR_ACCOUNT_SUB_ACTIVITY_CODE: '04',
    JUNIOR_ACCOUNT_SUCCESS_CODE: 'OPSWLO_PS0005',
    JUNIOR_ACCOUNT_TIPO_DOMIC: '01',
    JUNIOR_ACCOUNT_TIPO_INTERVENCION: '09',
    KEEPALIVE_TIME: 60, // secs
    KEYBOARD_ESC: 27,
    KEYBOARD_RETURN: 13,
    KEY_VALUE_REGEX: /^[^:]+:[^:]+$/,
    LEFT_ARROW_KEYCODE: 37,
    LEGAL_CORRESPONDENCE: 'legalCorrespondence',
    FISCAL_INFORMATION: 'fiscalInformation',
    LABEL_ALTERNATIVE_FUNDS: 'fondos_alternativos',
    LEGAL_INTERSTITIAL_HOLE: 'B.1.2',
    LETTERS_UP_TO_TWO: /^([A-Za-z]|[Ñ]|[ñ]){0,2}$/,
    LIMITED_CODE: 1,
    LIMITED_ORDER_CLASS: 1,
    LIMIT_CHARITY_AMOUNT: 99999999,
    LINK_BUTTON_TYPE: 'linkButtonType',
    LIQUIDATION_TABLE_MAX_ROWS_PER_VIEW: 5,
    LIST_NAME_MAX_LENGTH: 30,
    LOADING: 'Cargando...',
    LOANS: 'loans',
    LOANS_MORTGAGES_INVESTMENT_CREDIT: 'investment',
    LOANS_MORTGAGES_PREGRANTED_CREDIT: 'pregranted',
    LOCAL_ENVIRONMENT: 'LOCAL',
    LOGOUT_TIME_LIMIT: 300, // secs
    LOGOUT_TIME_ADDITIONAL: 10, // secs
    LOW_CREDIT_SCORE_DESCRIPTION: 'Denegada por excluyentes',
    MAX_FRACTION_LENGTH_IN_STORE: 2,
    MAX_FRACTION_LENGTH_IN_STORE_LIMITED: 3,
    MAX_LABEL: 'MÁX.',
    MAX_LIMIT_CREDIT_CARD: 3,
    MAX_CONTRIBUTION_PLANS: 1500,
    MERCADO_ALTERNATIVO_BURSATIL: 'MERCADO ALTERNATIVO BURSATIL',
    MERCADO_CONTINUO_ESPANOL: 'MERCADO CONTINUO ESPANOL',
    MIFID_STEP_ZERO: -1,
    MINICARD_STANDARD: '0000002',
    MINICARD_SUBTYPE: '007',
    MINIMAL_FUNDABLE_AMOUNT: -60,
    MIN_EXCHAGE_INDEXES: '11',
    MIN_LABEL: 'MÍN.',
    MOBILE_MAX_WIDTH: 767,
    MOBILE_PREFIX: '034',
    MOBILE_VALIDATION_REGEX: /^(6|7|(6\d|7[1-4])\d{0,7})$/,
    MONEY_REQUEST_VIEW: 'moneyRequestView',
    MONTHS: 'MESES',
    MORTGAGES: 'mortgages',
    MORTGAGES_COLLECTIVE_ID: 'Collective_0073-001-1712',
    MORTGAGE_TAXES_GROUP_CODE: 1,
    MULTICHANNEL_UI_COMPONENT: 5,
    MYPROFILE_ACCOUNTS_URL: '/myprofile/accounts',
    MYPROFILE_BROKER_ALTERNATIVE_FUNDS_URL: '/myprofile/broker/alternative-funds',
    MYPROFILE_BROKER_CALMEBACK_CORPORATES_OPERATIONS: '/myprofile/broker/callmeBack/corporatesOperations',
    MYPROFILE_BROKER_CONSULT_MIFID_URL: '/myprofile/broker/consult-mifid',
    MYPROFILE_BROKER_CONVENIENCE_MIFID_TEST_WIZARD_URL: '/myprofile/broker/convenience-mifid-test/wizard',
    MYPROFILE_BROKER_ERROR_URL: '/myprofile/broker/error',
    MYPROFILE_BROKER_ETFS_URL: '/myprofile/broker/etfs',
    MYPROFILE_BROKER_EXPLORE_ETFS_URL: '/myprofile/broker/explore/etfs',
    MYPROFILE_BROKER_EXPLORE_FUNDS_URL: '/myprofile/broker/explore/funds',
    MYPROFILE_BROKER_EXPLORE_ALTERNATIVE_FUNDS_URL: '/myprofile/broker/explore/alternative_funds',
    MYPROFILE_BROKER_EXPLORE_PLANS_URL: '/myprofile/broker/explore/plans',
    MYPROFILE_BROKER_EXPLORE_RESULTS_ETFS_URL: '/myprofile/broker/explore/results/etfs',
    MYPROFILE_BROKER_EXPLORE_RESULTS_FUNDS_URL: '/myprofile/broker/explore/results/funds',
    MYPROFILE_BROKER_EXPLORE_RESULTS_ALTERNATIVE_FUNDS_URL: '/myprofile/broker/explore/results/alternative_funds',
    MYPROFILE_BROKER_EXPLORE_RESULTS_PLANS_URL: '/myprofile/broker/explore/results/plans',
    MYPROFILE_BROKER_EXPLORE_RESULTS_STOCKS_URL: '/myprofile/broker/explore/results/stocks',
    MYPROFILE_BROKER_EXPLORE_RESULTS_URL: '/myprofile/broker/explore/results',
    MYPROFILE_BROKER_EXPLORE_RESULTS_WARRANTS_URL: '/myprofile/broker/explore/results/warrants',
    MYPROFILE_BROKER_EXPLORE_STOCKS_URL: '/myprofile/broker/explore/stocks',
    MYPROFILE_BROKER_EXPLORE_URL: '/myprofile/broker/explore',
    MYPROFILE_BROKER_EXPLORE_WARRANTS_URL: '/myprofile/broker/explore/warrants',
    MYPROFILE_BROKER_FUNDS_URL: '/myprofile/broker/funds',
    MYPROFILE_BROKER_FUTURES_URL: '/myprofile/broker/futures',
    MYPROFILE_BROKER_INTERNAL_TRANSFER_FUND: '/myprofile/broker/internalTransferFund',
    MYPROFILE_BROKER_LESS_THAN_ONE: '< 1',
    MYPROFILE_BROKER_ORDERSANDMOVEMENTS_URL: '/myprofile/broker/ordersAndMovements',
    MYPROFILE_BROKER_PENSION_PLAN_BENEFICIARIES_URL: '/myprofile/broker/pensionPlanBeneficiaries',
    MYPROFILE_BROKER_PENSION_PLAN_PERIODICAL_CONTRIBUTION_CANCEL_URL: '/myprofile/broker/pensionPlanPeriodicalContribution/cancel',
    MYPROFILE_BROKER_PENSION_PLAN_PERIODICAL_CONTRIBUTION_URL: '/myprofile/broker/pensionPlanPeriodicalContribution',
    MYPROFILE_BROKER_PLANS_URL: '/myprofile/broker/plans',
    MYPROFILE_BROKER_RIGHTS_SELL_URL: '/myprofile/broker/rightsSell',
    MYPROFILE_BROKER_STOCKS_SALE_ORDER_WIZARD_URL: '/myprofile/broker/stocks-sale-order/wizard',
    MYPROFILE_BROKER_STOCKS_URL: '/myprofile/broker/stocks',
    MYPROFILE_BROKER_SUBSCRIPTION_WIZARD_URL: '/myprofile/broker/subscription/wizard',
    MYPROFILE_BROKER_TRANSFER_EXTERNAL_FUND_URL: '/myprofile/broker/externalTransferFund',
    MYPROFILE_BROKER_URL: '/myprofile/broker',
    MYPROFILE_BROKER_WARRANTS_URL: '/myprofile/broker/warrants',
    MYPROFILE_GLOBAL_URL: '/myprofile/global',
    MYPROFILE_INSURANCES_URL: '/myprofile/insurances',
    MYWALLET_EVOLUTION_CHART_TYPE_HISTORY: 'history',
    MYWALLET_EVOLUTION_CHART_TYPE_ONEMONTH: 'onemonth',
    MYWALLET_EVOLUTION_CHART_TYPE_YTD: 'ytd',
    MY_PROFILE_ACCOUNT: 'account', //leave as lowercase as its being used in translations
    MY_PROFILE_BROKER_DERIVATIVES_MOVEMENTS_URL: '/myprofile/broker/derivatives-movements',
    MY_PROFILE_BROKER_DERIVATIVES_MOVEMENTS_WIZARD_URL: '/myprofile/broker/derivatives-movements/wizard',
    NGO_MODAL_CARD: 'NGO_MODAL_CARD',
    NGO_MODAL_TRANSFER: 'NGO_MODAL_TRANSFER',
    NL_MOBILE_VALIDATION_REGEX: /^[0]\d{0,9}$/,
    NL_PHONE_NUMBER_MIN_LENGTH: 9,
    NL_POSTAL_CODE_MAX_LENGTH: 6,
    NO: 'no',
    NONE_SEPA: 'NS',
    NON_ALPHANUMERIC_REGEX: /([^a-zA-Z0-9])/,
    NON_COLLECTIVE_ID: 'NON_COLLECTIVE_ID',
    NON_DOTS_FORMAT_TEL_REGEXP: /\./g,
    NON_NUMERIC_REGEXP: /[^0-9]+/g,
    NON_COLON_REGEX: /[^:]/g,
    NORMAL_DEVICES: 'NORMAL',
    NOTIFICATION: 'NOTIFICATION',
    NOTIFICATIONS_CURRENT_INDEX: 5,
    NOTIFICATIONS_STATUS_DELIVERED: 'delivered',
    NOTIFICATIONS_STATUS_ERROR: 'error',
    NOTIFICATIONS_STATUS_PENDING: 'pending',
    NOTIFICATIONS_STATUS_READ: 'read',
    NOTIFICATION_DEEP_LINK_HB_CHORE: 'HB_CHORE',
    NOTIFICATION_DEEP_LINK_HB_REQ_MONEY: 'HB_REQ_MONEY',
    NOTIFICATION_DEEP_LINK_SAVE_MONEY: 'HB_SAVE_MONEY',
    NOTIFICATION_MESSAGE_TYPE_DISCLAIMER: 'DISCLAIMER',
    NOTIFIED_RIGHTS: 'NOTIFIED',
    NO_ERROR: 1,
    NO_RENOVAR: 'NO RENOVAR',
    NUMBER_REGEX: /^\d+$/,
    NUMBER_WITH_DECIMALS_REGEX: /^-?\d*[,\.]{0,1}\d*$/,
    OCULTO_ENVIRONMENT: 'OCULTO',
    OFFICE: 'OFFICE',
    OFFERS_RENEWAL_LANDING: "OFFERS_RENEWAL_LANDING",
    OFFICES_FOR_CALLBACK: [101, 102, 103, 104, 105, 106, 107],
    OFFICE_TYPE_DIAMOND: 'Diamond',
    OFFICE_TYPE_DIAMOND_PLUS: 'Diamond Plus',
    OFFICE_TYPE_OFICINA_NACIONAL: 'Oficina Nacional',
    OFFICE_TYPE_PREMIUM: 'Premium',
    OKDISCOUNT_BRANCHS: ['iberia', 'galp', 'europcar'],
    OKDISCOUNT_GESTOR_NUMBER: '000213',
    OKDISCOUNT_NUMBER_DAY_PROMOTION: 3,
    OKDISCOUNT_POST_ERROR: 'KO',
    OKDISCOUNT_STATUS_ALREADY_ADDED: '0',
    OKDISCOUNT_STATUS_NOT_ADDED_WITHOUT_CONDITIONS: '2',
    OKDISCOUNT_STATUS_NOT_ADDED_WITH_CONDITIONS: '1',
    OK_SLIDER_TYPE_RANGE: 'range',
    OK_SLIDER_TYPE_SINGLE: 'single',
    ONE_HOLDER: 1,
    ONLY_LETTERS_ACCENTS_NUMBERS_SPACES_GUIDES_REGEX: /^[a-zA-ZÀ-ÿ_ñÑ_0-9-\s]*$/,
    ONLY_LETTERS_ACCENTS_NUMBERS_SPACES_REGEX: /^[a-zA-ZÀ-ÿ_ñÑ_0-9\s]*$/,
    ONLY_LETTERS_AND_NUMBERS_REGEX: /^[a-zA-Z_ñÑ_0-9\s]*$/,
    ONLY_LETTERS_AND_SPACES_REGEX: /^[a-zA-Z_ñÑ\s]*$/,
    ONLY_LETTERS_REGEX: /^[a-zA-Z_ñÑ]*$/,
    OPENBANK_PRODUCT_BANKING: 'banking',
    OPENBANK_PRODUCT_WEALTH: 'wealth',
    OPENYOUNG_ACCOUNT_FULLTYPE: '301101',
    OPENYOUNG_ACCOUNT_STANDARD: '0000001',
    OPENYOUNG_ACCOUNT_SUBTYPE: '101',
    OPENYOUNG_ACCOUNT_TYPE: '301',
    OPENYOUNG_CARD_OPERATION_CODE: '122',
    OPENYOUNG_CARD_STANDARD: '0000001',
    OPENYOUNG_CARD_SUBTYPE: '010',
    OPENYOUNG_CARD_TYPE: '506',
    OPENYOUNG_CHORE_APPROVED: 3,
    OPENYOUNG_CHORE_CANCEL: 6,
    OPENYOUNG_CHORE_DELETED: 7,
    OPENYOUNG_CHORE_EXPIRED: 5,
    OPENYOUNG_CHORE_PENDING: 2,
    OPENYOUNG_CHORE_REJECTED: 4,
    OPENYOUNG_CHORE_WIP: 1,
    OPENYOUNG_MAX_BALANCE: 1000,
    OPENYOUNG_MAX_CHILD_LIMIT: 10,
    OPENYOUNG_MIN_BALANCE: 0.1,
    OPEN_CREDIT_CARD: 'OPEN_CREDIT_CARD',
    OPEN_DEBIT_STANDARD: '0000020',
    OPEN_DEBIT_SUBTYPE: '002',
    OPEN_SECURITIES_LOCATION: 'Contratacion_OpenSecurities',
    OPEN_STUDENT_STANDARD: '0000008',
    OPEN_STUDENT_SUBTYPE: '002',
    OPEN_TRADER_URL_BASE: 'https://opentrader.openbank.es/auth/prorealtimeOpenbankNew.php',
    OPEN_YOUNG_KEY: 'openYoung',
    OPEN_YOUNG_PACK_KEY: 'openYoungPack',
    ORDER_BY_CREATED_ON: 'CREATED_ON',
    OTP_ORIGIN_ACCOUNT_PREDEFINED: '007301000000000000',
    PAYLATER_NUMSOR: 0,
    PAYLATER_SXVALUE: 'L',
    PAYROLL_ACCOUNT_STANDARD: '0000001',
    PAYROLL_ACCOUNT_SUBTYPE: '805',
    PAYROLL_ACCOUNT_TYPE: '300',
    PERCENTAGE_SYMBOL: '%',
    PERIODICITY_ANNUAL: 'ANNUAL',
    PERIODICITY_BIANNUAL: 'BIANNUAL',
    PERIODICITY_CONSTANTLY: 'CONSTANTLY',
    PERIODICITY_COUNT: 'COUNT',
    PERIODICITY_COUNT_DEFAULT: 1,
    PERIODICITY_DATE: 'DATE',
    PERIODICITY_HALF_YEARLY: 'Half-Yearly',
    PERIODICITY_MONTHLY: 'MONTHLY',
    PERIODICITY_QUARTERLY: 'QUARTERLY',
    PERIODICITY_WEEKLY: 'WEEKLY',
    PERIODICITY_DOCUMENT_SERVICE: {
        Weekly:'SEMANAL',
        Monthly:'MENSUAL',
        Quarterly:'TRIMESTRAL',
        Semiannual:'SEMESTRAL',
        Annual:'ANUAL',
    },
    PERSONETICS_SUBSCRIPTION_TERMS_AND_CONDS_LINK: 'https://www.openbank.es/assets/static/pdf/finanzas/Teminos_Condiciones_Mis_Finanzas.pdf',
    PERSONETICS: 'personetics',
    PFM_FILTERS: 'pfm',
    PHONES_TYPE: 'phonesType',
    PHONE_AND_MOBILE_NUMBER_REGEX: /^[6789]{1}[0-9]{8}$/,
    PHONE_NON_NUMERIC_REGEX: /^[^67]|[^\d]+/g,
    PHONE_NUMBER_MAX_LENGTH: 9,
    PHONE_NUMBER_MIN_LENGTH: 9,
    PHONE_NUMBER_REGEX: /^[6|7][0-9]{8}$/,
    PHONE_NUMBER_START_DIGIT: /^[6|7]/,
    PLANS: 'plans', //leave as lowercase as its being used in translations
    PLANS_ABLE_TO_BE_HIRED_FILTER: 'CustomCategoryId3:EQ:1',
    PLANS_ACTIVE: 'ACTIVO',
    PLANS_CANCELED: 'CANCELADO',
    PLANS_SUSPENDED: 'SUSPENDIDO',
    PLURAL_CHARACTER: 's',
    PORTABILITY: 'PORTABILITY',
    POSTAL_CODE_REGEX: /^\d{5}$/,
    POTENTIAL_COST: 'POTENTIALCOST',
    POTENTIAL_COST_TYPE: 'SECTION',
    POTENTIAL_SELL_ETF_COTS: 'potentialSellETFsCosts',
    POTENTIAL_SELL_FUNDS_COSTS: 'potentialSellFundsCosts',
    POTENTIAL_SELL_STOCKS_COSTS: 'potentialSellStocksCosts',
    POTENTIAL_SELL_WARRANT_COSTS: 'potentialSellWarrantsCosts',
    POTENTIAL_SELL_RIGHT_COSTS: 'potentialSellRightsCosts',
    PRECONTRACTUAL_DEPOSIT: 'PRECONTRACTUAL_DEPOSIT',
    BANKING_DEPOSIT_GENERIC_PRECONTRACTUAL: 'BANKING_DEPOSIT_GENERIC_PRECONTRACTUAL',
    BANKING_DEPOSIT_GENERIC_CONTRACTUAL: 'BANKING_DEPOSIT_GENERIC_CONTRACTUAL',
    PRECONTRACTUAL_DEPOSIT_RENEWAL: 'PRECONTRACTUAL_DEPOSIT_RENEWAL',
    PREFIX_SPANISH_ISIN_CODE: 'ES',
    PREMIUM_CARD: 'PREMIUM_CARD',
    PREMIUM_PACK_HIRING: 'premium_pack_hiring',
    PREMIUM_PACK_STANDARD: '0000003',
    PREPAID_CARD: "prepaidCard",
    PRE_GRANTED_CREDIT_DESCRIPTION: 'pre-granted-credit',
    PRE_GRANTED_CREDIT_STANDARD: '0000001',
    PRE_GRANTED_CREDIT_SUBTYPE: '999',
    PRE_GRANTED_CREDIT_TYPE: '999',
    PRODUCTION_DOMAIN: 'openbank.es',
    PRODUCTION_ENVIRONMENT: 'PRODUCCION',
    PRODUCT_BANNER_PACK_DIAMOND: 'pack_diamond',
    PRODUCT_BANNER_PACK_PREMIUM: 'pack_premium',
    PRODUCT_CHECKING_ACCOUNT: '8000000',
    PRODUCT_CONTRACTINDEX_PREMIUM_PACK: 'premium-pack',
    PRODUCT_PAYROLL_ACCOUNT: '8050000',
    PRODUCT_TYPE_ETF: 'etf',
    PRODUCT_TYPE_FUND: 'investment fund',
    PRODUCT_TYPE_FUND_UPGRADED: 'investmentfund',
    PRODUCT_TYPE_PLAN: 'pension plan',
    PRODUCT_TYPE_PLAN_UPGRADED: 'pensionplan',
    PRODUCT_TYPE_STOCKS: 'stock',
    PRODUCT_TYPE_WARRANTS: 'warrant',
    PRODUTCS_ACTIVE_TAB: 0,
    PROFILE_AVATAR_IMAGE_MAXIMUM_SIZE_MB: 3,
    PROFILE_AVATAR_IMAGE_MAX_UPLOAD_PIXELS: 512,
    PROFILE_EDITOR_UPDATE_OTP_CONTACT_TYPE: 'EMAIL',
    PROFILE_EDITOR_UPDATE_OTP_ON_EMAIL: '130',
    PROFILE_EDITOR_UPDATE_OTP_ON_SMS: '4',
    PROMOTION_ALREADY_HIRED_ERROR: 'PromocionClienteYaRegistradoCodigoPromoException',
    PROMOTIONAL_CODE_ERROR: 'promotional code error',
    PT_MOBILE_VALIDATION_REGEX: /^[9]\d{0,9}$/,
    PT_PHONE_NUMBER_MIN_LENGTH: 9,
    PT_POSTAL_CODE_MAX_LENGTH: 8,
    PUSH_NOTIFICATION: 'push-notification',
    QA_ENVIRONMENT: 'QA',
    QA_ISBAN_ENVIRONMENT: 'QA-ISBAN',
    RECEIPTS_FILTERS: 'receipts',
    RECEIPT_STATUS_CODES_CANCELATION_PENDING: ['PAN'],
    RECEIPT_STATUS_CODES_CANCELED: ['ANU'],
    RECEIPT_STATUS_CODES_PAID: ['APL'],
    RECEIPT_STATUS_CODES_PENDING: ['PAP', 'PTF', 'PTR'],
    RECEIPT_STATUS_CODES_REJECTED: ['APR', 'PTD'],
    RECEIPT_STATUS_CODES_RETURNED: ['DEV', 'PAB'],
    RECEIPT_STATUS_TERM_CANCELATION_PENDING: 'cancelationPending',
    RECEIPT_STATUS_TERM_CANCELED: 'canceled',
    RECEIPT_STATUS_TERM_PAID: 'paid',
    RECEIPT_STATUS_TERM_PENDING: 'pending',
    RECEIPT_STATUS_TERM_REJECTED: 'rejected',
    RECEIPT_STATUS_TERM_RETURNED: 'returned',
    REDIRECT_URL: 'redirect',
    REFERRALS_INVITATIONS_FILTER_GIFT_ALL: 'all',
    REFERRALS_INVITATIONS_FILTER_STATE_ALL: 'all',
    REFERRALS_INVITATIONS_FILTER_STATE_PAID: 'accomplished',
    REFERRALS_INVITATIONS_FILTER_STATE_PENDING: 'pending',
    REFERRALS_INVITATIONS_FILTER_STATE_USED: 'used',
    REFERRAL_STATUS_ACCOMPLISHED: 'ACCOMPLISHED',
    REFERRAL_STATUS_PENDING: 'PENDING',
    REGENERATE_SIGNATURE_HIGH_BIOCATCH_SCORE: 'REGENERATE_SIGNATURE_HIGH_BIOCATCH_SCORE',
    REGENERATE_SIGNATURE_INIT_ID: 254,
    SIGNATURE_KEY_CHANGE_LOW_SCORE: 255,
    REGENERATE_SIGNATURE_MAX_NUMBER_RETRIES: 3,
    REGENERATE_SIGNATURE_LOW_BIOCATCH_SCORE: 'REGENERATE_SIGNATURE_LOW_BIOCATCH_SCORE',
    REGEX_REMOVE_SPECIAL_SYMBOLS: /[^a-zA-ZÀ-ÿ_ñÑ_0-9-]/g,
    REJECT_MONEY_REQUEST_STATUS: 3,
    RENEWABLE_DEPOSIT_TYPE: 'renewabledeposit_type',
    RENTIER_CODE: '2',
    REPAGINATION_ALL: 'ALL',
    REPETITION_MAX_LIMIT: 6,
    REQUESTED_LIST_ACCOUNTS: 'C',
    REQUESTED_LIST_CARDS: 'T',
    REQUESTED_LIST_FUNDS: 'F',
    REQUESTED_LIST_PLANS: 'L',
    REQUESTED_LIST_SAVINGS: 'D',
    REQUESTED_LIST_WALLETS: 'V',
    REQUESTED_RIGHTS: 'REQUESTED',
    REQUIRED_SYMBOL: '*',
    RETAIL_LOAN_AD_ID: 'B.18.1',
    REVOLVING_CARD: 'REVOLVING_CARD',
    RIGHT_ARROW_KEYCODE: 39,
    ROUTE_TYPE_FROM_MOVEMENTS: 'fromMovement',
    SATURDAY_INDEX_DAY: 6,
    SAVINGS: 'savings', //leave as lowercase as its being used in translations
    SAVINGS_ACCOUNT_STANDARD: '0000001',
    SAVINGS_ACCOUNT_SUBTYPE: '006',
    SAVINGS_ACCOUNT_TYPE: '301',
    SAVINGS_ACCOUNT_WELCOME: 'SAVINGS_ACCOUNT_WELCOME',
    SAVINGS_ACCOUNT_WELCOME_PAYROLL: 'SAVINGS_ACCOUNT_WELCOME_PAYROLL',
    SAVINGS_REQUEST_VIEW: 'moneySavingsRequest',
    SECOND_STEP: 1,
    SEPA_INTERNATIONAL: 'SI',
    SEPA_NATIONAL: 'SN',
    SETTINGS_WALLETS: 'valores',
    SEVEN_ALPHANUMERIC_CHARACTERS: /^([a-zA-Z0-9]|[Ñ]|[ñ]){7}$/,
    SIGNATURE_KEY_ALL_DIGITS: 8,
    SIGNATURE_PASS_CODE_ARGUMENTS: [1, 3, 5, 6],
    SIXTH_STEP: 5,
    SLASH: '/',
    SMS: 'SMS',
    SMS_CODE_MAX_LENGTH: 4,
    SMS_CODE_REGEX: /^[A-Z0-9]{4}$/, // SMS CODE REQUIREMENTS: 4 characters. Alphanumeric. Letters in Upper case. Case sensitive.
    SMS_KEY_DIGITS_COUNT: 8,
    SOLIDARITY_CARD_STANDARD: '0000001',
    SOLIDARITY_CARD_SUBTYPE: '011',
    SOLIDARITY_CARD_TYPE: '506',
    SOLIDARITY_INFORMATION_LINK: 'https://www.openbank.es/assets/static/pdf/PieDePagina/ClausulaGDPR.pdf',
    ONG: 'ONG',
    SOLIDARITY_CARD: 'solidarity',
    SPAIN_COUNTRY_CODE: '34',
    SPAIN_PLANS_UNIVERSE_ID: 'FOESP$$PEN_2119',
    SPANISH_CONTINUES_MARKET_ISO_CODE: 'XMCE',
    SPANISH_CONTINUES_MARKET_ISO_CODE_GROW: 'GROW',
    SPANISH_CONTINUES_MARKET_ISO_CODE_ONLY_STOCK: 'MABX',
    SPANISH_MARKETS_ISO_CODE: ['xbil', 'xval', 'xbar', 'xlat', 'xmce', 'mabx', 'grow'],
    SPANISH_DAYS: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"],
    SPC_SYMBOL: 'OB_Home_MyN_Indices',
    STAGING_ENVIRONMENT: 'STAGING',
    STANDART_PRODUCT_CUSTOM_SECURITY_ID: '1',
    START_AMOUNT_GROWTH_ETF_GRAPH: 10000,
    STOCKS_BUY: 'STOCKS_BUY',
    STOCKS_SELL: 'STOCKS_SELL',
    SUMMARY_HELPER_TYPE_AMOUNT: 'AMOUNT',
    SUMMARY_HELPER_TYPE_TEXT: 'TEXT',
    SUNDAY_INDEX_DAY: 0,
    TABLET_MAX_WIDTH: 1199,
    TABLET_MIN_WIDTH: 768,
    TABLET_PRO_MIN_WIDTH: 1024,
    TAB_KEYCODE: 9,
    TAX_IDENTIFICATION_NUMBER_MIN_LENGTH: 2,
    TEALIUM_EVENT_PRODUCT_IMPRESSION: 'product_impression',
    TEXT_TARGET_TYPE: 'textTargetType',
    THIRD_DIRECTIVE_END: ' # # #',
    THIRD_DIRECTIVE_WITHOUT: ' # # # # # # # # #',
    THIRD_STEP: 2,
    TIMEOUT_ERROR: 'TIMEOUT',
    TIN_TYPE: 'TIP',
    TINTAE_TYPE: 'tintaeType',
    TOTAL_STAR_COUNT: 5,
    TO_MARKET_CODE: 4,
    TO_MARKET_ORDER_CLASS: 4,
    TRANSFERS_LANDING_LIMIT: 5,
    TRANSFER_COSTS_BENEFICIARY: 'B',
    TRANSFER_COSTS_BENEFICIARY_TEXT: 'COM+GTS POR CTA BENEFICIARIO',//comes from a service
    TRANSFER_COSTS_SENDER: 'O',
    TRANSFER_COSTS_SENDER_TEXT: 'COM+GTS POR CTA ORDENANTE',//comes from a service
    TRANSFER_COSTS_SHARED: 'C',
    TRANSFER_COSTS_SHARED_TEXT: 'COM+GTS COMPARTIDOS',//comes from a service
    TRANSFER_EMITTED_DAY_AFTER: 'P',
    TRANSFER_EMITTED_DAY_BEFORE: 'A',
    TRANSFER_LIQUIDATION_COMMISSION_COSTS_BENEFICIARY_ID: '292',
    TRANSFER_LIQUIDATION_COMMISSION_COSTS_INCOMPLETE_INFO_ID: '359',
    TRANSFER_LIQUIDATION_COMMISSION_COSTS_OWNER_ID: '291',
    TRANSFER_LIQUIDATION_COMMISSION_COSTS_SHARED_ID: '293',
    TRANSFER_LIQUIDATION_CONVERSION_ID: '646',
    TRANSFER_LIQUIDATION_MAIL_COSTS_ID: '549',
    TRANSFER_LIQUIDATION_SWIFT_COSTS_ID: '546',
    TRANSFER_MAX_IMPORT: 9999999.99,
    TRANSFER_MIN_IMPORT: 0,
    TRANSFER_PAYMENT_BY_BENEFICIARY_CODE: 0,
    TRANSFER_PAYMENT_BY_ISSUER_CODE: 1,
    TRANSFER_PAYMENT_BY_SHARED_CODE: 2,
    TWENTY_TO_TWTENTYFOUR_ALPHANUMERIC_CHARACTERS: /^(([A-Za-z]|[Ñ]|[ñ]){2}[0-9]{0,22})$|^([0-9]){0,20}$/,
    TWO_DECIMALS: 2,
    TWO_HOLDERS: 2,
    TYPE_ACTIVOS_REALES: 'activosreales',
    TYPE_CM: 'cm',
    TYPE_CONTRACT_MASTERCARD: '506',
    TYPE_DERECHOS: 'derechos',
    TYPE_ETFS: 'etfs',
    TYPE_FUNDS: 'funds',
    ALTERNATIVE_INVESTMENT: 'inversionAlternativa',
    ALTERNATIVE_FUNDS: 'alternativeFunds',
    ALTERNATIVE_FUNDS_INPUT_VALUE: 'ALTERNATIVE_FUNDS',
    TYPE_ALTERNATIVE_FUNDS_PG: 'FONDOS_ALTERNATIVOS',
    TYPE_FUTURES: 'futures',
    TYPE_GENERAL_PRODUCT: 'values',
    TYPE_GESTION_ALTERNATIVA: 'gestionalternativa',
    TYPE_MERCADO_MONETARIO: 'mercadomonetario',
    TYPE_MIXTOS: 'mixtos',
    TYPE_MIXED: 'mixed',
    TYPE_OTHERS: 'others',
    TYPE_OTROS: 'otros',
    TYPE_PENSIONS: 'pensions',
    TYPE_PLANS: 'plans',
    TYPE_RENTA_FIJA: 'rentafija',
    TYPE_RENTA_VARIABLE: 'rentavariable',
    TYPE_STOCKS: 'stocks',
    TYPE_WARRANTS: 'warrants',
    UNEXPECTED_ERROR: 'Se ha producido un error inesperado',
    UNEXPECTED_ERRORS: ['Se ha producido un error inesperado'],
    UNKNOWN_DEVICES: 'UNKNOWN',
    UPGRADE_TO_PAYROLL_ACCOUNT_ACCOUNT_SUBTYPE: '800',
    UPGRADE_TO_PAYROLL_ACCOUNT_ACCOUNT_TYPE: '300',
    USER_MAX_UNREAD_NOTIFICATIONS: '99+',
    USER_NOTIFICATIONS_GET_MIN_SIZE: '5',
    USER_NOTIFICATIONS_GET_NEXT_SIZE: '15',
    VALID_STATUS_ECARD: 'EV',
    VIAT_CARD: 'VIAT_CARD',
    VIEW_MIFID_TEST_OPTION_DISABLED: true,
    VIEW_MIFID_TEST_OPTION_DISABLED_CONTACT_NUMBER: '+34\u00a091\u00a0177\u00a033\u00a016', //'+34 91 177 33 16',
    VIRTUAL_CREDIT_CARD_PRODUCT_STANDARD: '0000001',
    VIRTUAL_CREDIT_CARD_PRODUCT_SUBTYPE: '803',
    VIRTUAL_CREDIT_CARD_PRODUCT_TYPE: '500',
    VIRTUAL_DEBIT_CARD_PRODUCT_STANDARD: '0000001',
    VIRTUAL_DEBIT_CARD_PRODUCT_SUBTYPE: '012',
    VIRTUAL_DEBIT_CARD_PRODUCT_TYPE: '506',
    VK_NINE: 57,
    VK_NINE_NUMPAD: 105,
    VK_ZERO: 48,
    VK_ZERO_NUMPAD: 96,
    WALLETS: 'wallets', //leave as lowercase as its being used in translations
    WARNING: 2,
    WARRANTS_BUY: 'WARRANTS_BUY',
    WARRANTS_SELL: 'WARRANTS_SELL',
    WEB: 'WEB',
    WEB_FEATURE_FLAGGING: 'web-feature-flagging',
    WELCOME_ACCOUNT_DE_STANDARD: '0000001',
    WELCOME_ACCOUNT_DE_SUBTYPE: '014',
    WELCOME_ACCOUNT_DE_TYPE: '301',

    WELCOME_ACCOUNT_ES_STANDARD: '0000011',
    WELCOME_ACCOUNT_ES_SUBTYPE: '007',
    WELCOME_ACCOUNT_ES_TYPE: '301',

    WELCOME_ACCOUNT_NL_STANDARD: '0000001',
    WELCOME_ACCOUNT_NL_SUBTYPE: '019',
    WELCOME_ACCOUNT_NL_TYPE: '301',

    WELCOME_ACCOUNT_PT_STANDARD: '0000001',
    WELCOME_ACCOUNT_PT_SUBTYPE: '017',
    WELCOME_ACCOUNT_PT_TYPE: '301',

    WELCOME_ACCOUNT_PAYROLL_STANDARD: '0000010',
    WELCOME_ACCOUNT_PAYROLL_SUBTYPE: '010',
    WELCOME_ACCOUNT_PAYROLL_TYPE: '301',
    WELCOME_ACCOUNT_FLOW: 'WELCOME_ACCOUNT_FLOW',

    WELCOME_PAYROLL_ACCOUNT_PRECONTRACTUAL_LINK: 'https://bancoonline.openbank.es/csopen/StaticBS?blobcol=urldata&blobheader=application%2Fpdf&blobkey=id&blobtable=MungoBlobs&blobwhere=1287277332497&cachecontrol=immediate&ssbinary=true&maxage=3600',
    WITHOUT_ACTIVITY_CODE: '4',
    WITHOUT_NEW_MONEY_DEPOSIT_URL: '/myprofile/purchase/deposit',
    WIZARD_FIFTH_STEP: 5,
    WIZARD_SIXTH_STEP: 6,
    WIZARD_FIRST_STEP: 1,
    WIZARD_FOURTH_STEP: 4,
    WIZARD_HORIZONTAL_VIEW_MODE: 0,
    WIZARD_SECOND_STEP: 2,
    WIZARD_FIFTH_STEP: 5,
    WIZARD_THIRD_STEP: 3,
    WIZARD_VERTICAL_VIEW_MODE: 1, // When the wizard is to be shown in vertical steps.
    YES: 'si',
    ZERO_UP_TO_NINE_ALPHANUMERIC_CHARACTERS: /^([A-Za-z0-9]|[Ñ]|[ñ]){0,9}$/,
    ZERO_UP_TO_SEVEN_ALPHANUMERIC_CHARACTERS: /^([A-Za-z0-9]|[Ñ]|[ñ]){0,7}$/,
    ZERO_UP_TO_SIXTY_ALL_CHARACTERS: /^([^]){0,60}$/,
    URL_REGEX: /:\/\/[a-zA-Z]*(?:\.(dev|stg|qa|ocu))?\.openbank\.(\w{2})/,
    REMOVE_STYLE_REGEX: /(className="trusted-device-block-view__printable_wrap.+?")/gm,
    PROFILE_EDITOR_OTP: 160,
    PROFILE_EDITOR_OTP_V2: 241,
    BENIFICIARY_TECH_ERROR: 'ERROR TECNICO. CONTACTE CON SU BANCO .',
    BENIFICIARY_COUNTRYMATCH_ERROR: 'PAIS DESTINO DEBE SER IGUAL AL PAIS DE LA CUENTA',
    BENIFICIARY_CORRECTIBAN_ERROR: 'NECESITA BBAN CORRECTO',
    MODAL_MAX_LIMIT_EVENT: "transferencia/realizacion",
    MODAL_MAX_LIMIT_ERROR: "modal/error-transferencia/importe max excedido",
    MODAL_MIN_LIMIT_EVENT: "modal/contratar depositos",
    TRANSFER_COST_COMMISION_VALUE: 0.007,
    OVERDRAFT_LOAN_TYPE: '999',
    OVERDRAFT_LOAN_SUBTYPE: '99',
    OVERDRAFT_LOAN_STANDARD: '0000001',
    OVERDRAFT_LOAN_MAILTYPE: {
        PRECONTRACTUAL: 'precontractual',
        CONTRACTUAL: 'contract'
    },
    EASY_LOAN_MAILTYPE: {
        PRECONTRACTUAL: 'precontractual',
        CONTRACTUAL: 'contract'
    },
    EASY_LOAN_PRODUCT: '143',
    CARDS: {
        CREDIT: 'credit',
        DEBIT: 'debit',
    },
    PAYMENT_AMOUNT_IDENTIFIER: {
        ES: ',',
        EN: '.',
    },
    BROKER_HIRE_INVESTMENT_STEP_2_NO: false,
    BROKER_HIRE_INVESTMENT_STEP_2_YES: true,
    LEGAL_DOCUMENTS_TYPES: {
        PROSPECTUS: 'Prospectus',
        INFORME_SEMESTRAL: 'informeSemestral',
        DFI: 'DFI',
        RISKS: 'riesgos',
        RECOMMENDATIONS: 'asesoramiento',
    },
};